import React, {useCallback, useEffect, useRef, useState} from "react";
import SysMessageWrap from "./style";
import BackHeader from "../../components/back-header/back-header";
import { RouteChildrenProps } from 'react-router-dom'
import http from '../../common/js/http'
import ScrollWrap from "../../components/scroll-wrap/scroll-wrap";
import {Icon} from "antd-mobile";
import BottomLoading from "../../components/bottom-loading/bottom-loading";

const SIZE = 20

const SysMessage: React.FC<RouteChildrenProps> = (props) => {
  const [list, setList] = useState<any[]>([])
  const [showLoading, setLoading] = useState<boolean>(false)

  const copyList = useRef<any[]>([])

  const getList = useCallback((loading = false) => {
    return http.post('/webapi/Message/getSystemMsg', {
      lastid: copyList.current[copyList.current.length - 1] && copyList.current[copyList.current.length - 1].id,
      size: SIZE,
      hideLoading: loading
    })
      .then(({ data: { data } }) => {
        copyList.current = [...copyList.current, ...data]
        setList([...copyList.current])
        setLoading(data.length >= SIZE)
      })
  }, [])

  useEffect(() => {
    getList()
  }, [getList])

  const loadMore = useCallback(() => {
    if (!showLoading) return Promise.resolve()
    return getList(true)
  }, [showLoading, getList])

  return (
    <SysMessageWrap>
      <BackHeader title='系统消息' {...props} />
      <div className="list">
        <ScrollWrap loadMore={loadMore}>
          {list.map(item => {
            return (
              <a onClick={(e) => { if (!item.link) e.preventDefault() }} href={item.link} key={item.id} className='msg'>
                <p className="date">{item.create_time}</p>
                <div className="content">
                  {item.image_url && <div className="img" style={{ backgroundImage: `url(${item.image_url})` }} />}
                  <h4>{item.title}</h4>
                  <p>{item.content}</p>
                  {item.link && <span>查看详情 <Icon type='right' size='xxs' /></span>}
                </div>
              </a>
            )
          })}
          {showLoading && <BottomLoading/>}
        </ScrollWrap>
      </div>
    </SysMessageWrap>
  )
}

export default SysMessage
