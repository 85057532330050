import React, {DetailedHTMLProps, useCallback, useRef, useState} from 'react'
import { Icon, Toast } from 'antd-mobile'
import LoginWrap from './style'
import { Link, RouteChildrenProps } from 'react-router-dom'
import http from '../../common/js/http'
import { setItem } from '../../common/js/storage'
import { SetUserInfo } from '../../store/action'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
 // eslint-disable-next-line
import parseSearch from '../../common/util/parse-query'
interface LoginProps extends RouteChildrenProps {
  setUserInfo(data: any): void
}

const Login: React.FC<LoginProps> = ({ history, setUserInfo }) => {
  const [pwd, setPwd] = useState<string>('')
  const [account, setAccount] = useState<string>('')

  const submit = useCallback(() => {
    if (!account) {
      Toast.fail('请输入正确的手机号', 1, undefined, false)
      return
    }
    if (!pwd) {
      Toast.fail('请输入密码', 1, undefined, false)
      return
    }
    if (pwd.length < 6) {
      Toast.fail('密码不少于6位', 1, undefined, false)
      return
    }
    http.post('/webapi/user/login', {
      account,
      pwd
    })
      .then(({ data: { data } }) => {
        setItem('uid', data.id)
        setItem('token', data.token)
        setItem('nick_name', data.nick_name)
        setItem('txim_sign', data.txim_sign)
        setUserInfo(data)
        let page = window.location.search.split('?page=')[1]
        if(page) {
          window.localStorage.setItem('flag',String(1))
          history.replace(page)
        }else{
          history.goBack()
        }
      })
  }, [pwd, account, setUserInfo, history])

  const phoneRef = useRef<DetailedHTMLProps<any, any>>()
  const passwordRef = useRef<DetailedHTMLProps<any, any>>()

  const changePhone = useCallback(() => {
    setAccount(phoneRef.current.value)
  }, [phoneRef])

  const changePaw = useCallback(() => {
    setPwd(passwordRef.current.value)
  }, [passwordRef])

  return (
    <LoginWrap>
      <div className="head">
        <Icon type='left' size='lg' onClick={() => {history.goBack()}} />
      </div>
      <h3>手机号登录</h3>
      <div className="phone">
        <p className="phone-pre">+86</p>
        <input onChange={changePhone} ref={phoneRef} type="text" placeholder='请输入11位手机号' />
      </div>
      <div className="password">
        <input onChange={changePaw} ref={passwordRef} type="password" placeholder='请输入密码'/>
      </div>
      <Link className='forget' to='/forget-password'>忘记密码</Link>
      <div onClick={submit} className="submit">登录</div>
      <Link to='/register' className='register'>立即注册</Link>
    </LoginWrap>
  )
}

const mapAction = (dispatch: Dispatch) => {
  return {
    setUserInfo(info: any) {
      dispatch(SetUserInfo(info))
    }
  }
}

export default connect(null, mapAction)(Login)
