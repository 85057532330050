import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react'
import { ScoreListWrap } from './style'
import http from '../../common/js/http'
import ScoreItem from './score-item'
import BottomLoading from '../bottom-loading/bottom-loading'
import parseSearch from "../../common/util/parse-query";
import { getDate } from "../../common/util/transtime";

interface ListProps {
  type: string
  location: any
}

interface DateListType {
  date: string,
  week: string,
  stamp: string
  tag: string
}

const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

const urls: string[] = [
  'webapi/football/getRecMatch',
  '/webapi/Football/finishMatchList',
  '/webapi/football/getDateMatch',
  '/webapi/football/getAttentMatchlist'
]

const size = 10

const ScoreList: React.FC<ListProps> = ({ type, location }) => {
  const [list, setList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [activeDate, setActDate] = useState<string>('')
  const [isFinished, setFinished] = useState<boolean>(true)

  const page = useRef<number>(1)
  const eventids = useRef<string>('')

  const getList = useCallback((hideLoading: boolean = false, stamp: string = '') => {
    const data: any = {
      page: page.current,
      size,
      date: stamp || activeDate,
      hideLoading
    }
    const { type: urlType } = parseSearch(location.search)
    if (eventids.current && type === urlType) {
      data.eventids = eventids.current
    }
    return http.post(urls[Number(type)], data)
      .then(({ data: { data } }) => {
        console.log('data--', data.matchs)
        if (data.matchs) {
          setFinished(data.matchs.length < size)
          setList(page.current > 1 ? list.concat(data.matchs) : data.matchs)
          setLoading(false)
        } else {
          setFinished(data.length < size)
          setList(page.current > 1 ? list.concat(data) : data)
          setLoading(false)
        }


      })
  }, [type, page, list, location, activeDate])

  const dateList = useMemo(() => {
    if (type === '3') return []
    let current = Date.now()
    let dateList: DateListType[] = [
      {
        date: getDate((current - 1000 * 60 * 60 * 24) / 1000, 'mm月dd日'),
        week: '',
        tag: '昨天',
        stamp: getDate((current - 1000 * 60 * 60 * 24) / 1000, 'yy-mm-dd')
      },
      {
        date: getDate(current / 1000, 'mm月dd日'),
        week: '',
        tag: '今天',
        stamp: getDate(current / 1000, 'yy-mm-dd')
      },
      {
        date: getDate((current + 1000 * 60 * 60 * 24) / 1000, 'mm月dd日'),
        week: '',
        tag: '明天',
        stamp: getDate((current + 1000 * 60 * 60 * 24) / 1000, 'yy-mm-dd')
      }
    ]
    setActDate(dateList[1].stamp)
    for (let i = 1; i < 5; i++) {
      let date = 0
      if (type === '1') {
        date = current - (1000 * 60 * 60 * 24) * i
      } else {
        date = current + (1000 * 60 * 60 * 24) + (1000 * 60 * 60 * 24) * i
      }
      dateList.push({
        date: getDate(date / 1000, 'mm月dd日'),
        week: weeks[new Date(date).getDay()],
        tag: '',
        stamp: getDate(date / 1000, 'yy-mm-dd')
      })
    }
    return dateList
  }, [type])

  useEffect(() => {
    const { eventids: ids } = parseSearch(location.search)
    eventids.current = ids
    getList()
    // eslint-disable-next-line
  }, [])

  const loadMore = useCallback(() => {
    if (loading) return
    setLoading(true)
    page.current++
    getList(true)
  }, [getList, loading])

  const collection = useCallback((index: number) => {
    http.post('/webapi/Football/attentMatch', {
      matchid: list[index].id,
      type: Number(!list[index].attented)
    })
      .then(() => {
        const copy = [...list]
        copy[index].attented = Number(!copy[index].attented)
        // if (!copy[index].attented) {
        //   copy.splice(index, 1)
        // }
        setList(copy)
      })
  }, [list])

  const changeDate = useCallback((stamp: string) => {
    setActDate(stamp)
    page.current = 1
    getList(false, stamp)
  }, [getList])

  return (
    <ScoreListWrap>
      {type === '0' || type === '2' ? <div className='date-list'>
        {dateList.map(item => {
          return <div onClick={() => { changeDate(item.stamp) }} className={activeDate === item.stamp ? 'active date-item' : 'date-item'} key={item.date}>
            <p>{item.date}</p>
            <p>{item.tag || item.week}</p>
          </div>
        })}
      </div> : null}
      <div className="list">
        {
          list.map((item, index) => item.awayteam ? <ScoreItem collection={collection} index={index} score={item} key={item.id} /> : null)
        }
        {!isFinished ? <BottomLoading loadMore={loadMore} /> : null}
      </div>
    </ScoreListWrap>
  )
}

export default ScoreList
