import React, { useCallback, useState, Fragment } from 'react'
import { Tabs, Icon } from 'antd-mobile'
import trans from '../../common/util/px'
// import { Icon } from 'antd-mobile'
// import trans from '../../common/util/px'
import HomeWrap from './style'
import { connect } from 'react-redux'
import { StateType } from '../../store/reducer'
import Recommend from '../recommend/recommend'
import parseSearch from '../../common/util/parse-query'
import LiveList from '../live-list/live-list'
// import parseSearch from '../../common/util/parse-query'
// import LiveList from '../live-list/live-list'
import ReactDOM from "react-dom";
import TabBar from '../../components/tabbar/tabbar'
import DownloadBar from '../../components/downloadBar/downloadBar'
 // eslint-disable-next-line
import {log} from "util";

interface HomeProps {
  categories: any[],
  history: any
  location: any
}

const Home: React.FC<HomeProps> = ({ categories, history, location }) => {
  // let pc = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? false : true
  // if(pc) {
  //   window.location.replace('https://' + window.location.host.replace('m.','www.'))
  // }
  const [page, setPage] = useState<string>(parseSearch(location.search).type || '0')
  const [type, setType] = useState<string>( '0')

  const [closeStatus, setCloseStatus] = useState<boolean>( true)
  // const [page, setPage] = useState<string>(parseSearch(location.search).type || '0')

  const tabs = [
    {
      title: <div>全部</div>,
      key: '-1'
    },
    {
      title: <div>推荐</div>,
      key: '0'
    }
  ]

  categories.forEach((item: any) => {
    tabs.push({
      title: <div>{ item.title }</div>,
      key: item.id.toString()
    })
  })

  const changeQuery = useCallback((type) => {
    setPage(type)
    setType(type)
    history.replace({
      pathname: '/',
      search: '?type=' + type
    })
  }, [history])
   // eslint-disable-next-line
  const close = useCallback(() => {
    setCloseStatus(false)
     // eslint-disable-next-line
  }, [closeStatus])

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
      <Fragment>
        <HomeWrap>
          <div className="icon-search">
            <img src={require('./logo_share.png')} alt=""/>
            <Icon type='search' onClick={() => { history.push('/search') }} />
          </div>
          <Tabs tabs={tabs}
                swipeable={false}
                page={page}
                initialPage={parseSearch(location.search).type || '0'}
                tabBarUnderlineStyle={{transform: 'scaleY(1.2) scaleX(0.2)'}}
                tabBarActiveTextColor='#000'
                prerenderingSiblingsNumber={0}
                tabBarTextStyle={{ fontSize: trans(30), color: '#6F6F6F' }}
                onChange={(tab, index) => { changeQuery(tab.key) }}
          >
            {tabs.map((item) => <div className="content-wrap" key={item.key}>
                  {item.key === '0' ? <Recommend/> : <LiveList type={item.key} />}
                </div>
            )}
          </Tabs>
          {/*<div className="icon-search" onClick={() => { history.push('/search') }}>*/}
          {/*  <Icon type='search'/>*/}
          {/*</div>*/}
          {/*<Tabs tabs={tabs}*/}
          {/*      page={page}*/}
          {/*      initialPage={parseSearch(location.search).type || '0'}*/}
          {/*      tabBarUnderlineStyle={{transform: 'scaleY(1.2) scaleX(0.2)'}}*/}
          {/*      tabBarActiveTextColor='#000'*/}
          {/*      prerenderingSiblingsNumber={0}*/}
          {/*      tabBarTextStyle={{ fontSize: trans(30), color: '#6F6F6F' }}*/}
          {/*      onChange={(tab, index) => { changeQuery(tab.key) }}*/}
          {/*>*/}
          {/*  {tabs.map((item) => <div className="content-wrap" key={item.key}>*/}
          {/*      {item.key === '0' ? <Recommend/> : <LiveList type={item.key} />}*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</Tabs>*/}
          {/*<Recommend/>*/}
        </HomeWrap>
        {type.toString() === '0' ? <DownloadBar /> : null}
        {/*{ReactDOM.createPortal(, document.body)}*/}
        {ReactDOM.createPortal(<TabBar/>, document.body)}
      </Fragment>
  )
}

const mapState = (state: StateType) => {
  return {
    categories: state.categories
  }
}

export default connect(mapState, null)(Home)

