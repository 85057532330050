import React, {useCallback, useEffect, useRef, useState} from "react";
import EditAccountWrap from "./style";
import BackHeader from "../../components/back-header/back-header";
import { RouteChildrenProps } from 'react-router-dom'
import trans from "../../common/util/px";
import { connect } from 'react-redux'
import {Toast} from "antd-mobile";
import http from "../../common/js/http";

interface EditAccountProps extends RouteChildrenProps {
  userInfo: any
}

const EditAccount: React.FC<EditAccountProps> = ({ userInfo, history, location, match }) => {
  const [account, setAccount] = useState<string>( '')
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [sec, setSec] = useState<number>(60)

  const timer = useRef<any>()

  useEffect(() => {
    if (userInfo.account) setPhone(userInfo.account)
  }, [userInfo])

  const sendCode = useCallback(() => {
    if (!/^1\d{10}$/.test(phone)) {
      Toast.fail('请输入正确的手机号', 1, undefined, false)
      return
    }
    http.post('/webapi/user/sendVerifyCode', {
      mobile: phone
    })
      .then(() => {
        Toast.success('发送成功')
        let sec = 60
        timer.current = setInterval(() => {
          setSec(--sec)
          if (sec <= 0) {
            setSec(60)
            clearInterval(timer.current)
            timer.current = undefined
            return
          }
        }, 1000)
      })
  }, [phone])

  const changeName = useCallback((e: any) => {
    setName(e.target.value)
  }, [])

  const changeAccount = useCallback((e: any) => {
    setAccount(e.target.value)
  }, [])

  const changeCode = useCallback((e: any) => {
    setCode(e.target.value)
  }, [])

  const submit = useCallback(() => {
    if (!name) {
      Toast.fail('请输入支付宝姓名', 1, undefined, false)
      return
    }
    if (!account) {
      Toast.fail('请输入支付宝账户', 1, undefined, false)
      return
    }
    if (!code) {
      Toast.fail('请输入验证码', 1, undefined, false)
      return
    }

    http.post('/webapi/withdraw/editCashAccount', {
      alipay_name: name,
      alipay_account: account,
      smscode: code
    })
      .then(() => {
        history.goBack()
      })
  }, [name, account, code, history])

  return (
    <EditAccountWrap>
      <BackHeader title='管理提现账户' history={history} location={location} match={match} />
      <div style={{ height: trans(40) }} />
      <div className="input">
        <label>支付宝姓名</label>
        <input onChange={changeName} type="text" value={name} placeholder='请输入支付宝姓名' />
      </div>
      <div className="input">
        <label>支付宝账号</label>
        <input onChange={changeAccount} type="text" value={account} placeholder='请输入支付宝账号' />
      </div>
      <div className="input">
        <label>手机号</label>
        <input disabled={true}  type="text" value={phone} placeholder='请输入手机号' />
      </div>
      <div className="input">
        <label>手机号</label>
        <input onChange={changeCode} type="text" value={code} placeholder='请输入手机验证码' />
        <button onClick={sendCode}>{sec < 60 ? `${sec}s` : '获取验证码'}</button>
      </div>
      <button onClick={submit} className="submit">立即修改账户</button>
    </EditAccountWrap>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo
})

export default connect(mapState, null)(EditAccount)
