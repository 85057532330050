import React, { useCallback, useEffect, useRef, useState } from 'react'
import MessageWrap from './style'
import { connect } from 'react-redux'
import LevelIcon from '../../common/js/level-icon'
// @ts-ignore
import DOMPurify from 'dompurify';

import { SetTextFilter, SetUserInfo } from "../../store/action";
import { useHistory } from 'react-router-dom'
// @ts-ignore
import TIM from 'tim-js-sdk'
import { Toast, Modal } from "antd-mobile";
import { removeItem, setItem } from "../../common/js/storage";
// eslint-disable-next-line
import { getDate } from "../../common/util/transtime";
import http from "../../common/js/http";
import moment from "moment"
// eslint-disable-next-line
import { getConfig } from '../../store/action'
// eslint-disable-next-line
import { RouteChildrenProps } from "react-router-dom";
import { formateSensitiveWord } from '../../common/util/formateText';
import { decryptAES } from "../../common/util/cryptoUtils";

let sendTimer: any = null

const alert = Modal.alert



interface MessageProps {
  roomId: string
  sdkConfig?: any
  type?: string
  userInfo?: any
  isAttend?: boolean
  imClose?: any,
  updateInfo?: any,
  anchor?: any,
  detail?: any
}
// eslint-disable-next-line
const Message: React.FC<MessageProps> = ({ roomId, sdkConfig, type = 'video', userInfo, isAttend, imClose, updateInfo, anchor, detail }) => {
  const [msgs, setMsg] = useState<any[]>([])
  const [inputMsg, setInput] = useState<string>('')
  const history = useHistory()
  const tim = useRef<any>()
  const copyMsgs = useRef<any[]>([])
  const scroll = useRef<any>()
  const input = useRef<any>()
  const [isJoin, setIsJoin] = useState<boolean>(false)

  const showMsg = useCallback((data: any) => {
    const nMsg = DOMPurify.sanitize(data.Data.chat.message, { USE_PROFILES: { html: false } });
    const nData = data
    nData.Data.chat.message = nMsg

    copyMsgs.current = [...copyMsgs.current, nData]
    setMsg(copyMsgs.current)
    if (scroll.current) {
      scroll.current.scrollTop = scroll.current.scrollHeight
    }
  }, [copyMsgs])

  // eslint-disable-next-line
  const sendMsg = useCallback((message: string, action: string) => {
    let userName = localStorage.getItem('userName') || ''
    let nickName = localStorage.getItem('nick_name') || ''
    const content = {
      Action: action,
      Data: {
        chat: {
          sender: {
            id: userInfo.id,
            nick_name: nickName || '游客' + userName.slice(userName.length - 3),
            avatar: userInfo.avatar,
            user_level: userInfo.user_level ? userInfo.user_level.toString() : '1',
            vip_level: userInfo.vip_level,
            vip_date: userInfo.vip_date,
            is_anchor: userInfo.is_anchor
          },
          message: message
        }
      }
    }
    const msgBody: any = tim.current.createCustomMessage({
      to: roomId,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        data: JSON.stringify(content)
      }
    })
    tim.current.sendMessage(msgBody)
      .then(() => {
        showMsg(content)
        setInput('')
      })
      .catch((e: Error) => {
        // Toast.fail(e.message)
      })
    // eslint-disable-next-line
  }, [showMsg, userInfo, roomId, setInput])

  // eslint-disable-next-line
  const logOut = useCallback(() => {
    removeItem('uid')
    removeItem('token')
    window.localStorage.clear()
    SetUserInfo({})
    history.push('/')
    window.location.reload()
    // eslint-disable-next-line
  }, [history])
  // eslint-disable-next-line
  const getLevelColor = useCallback((level) => {
    let array = JSON.parse(window.localStorage.getItem('userLevelList') || '[]')
    for (let i = 0; i < array.length; i++) {
      const element = array[i] as any
      if (Number(element.level) === Number(level)) {
        return element.color
      }
    }
  }, [])
  const onKickedOut = useCallback((e) => {
    Toast.fail('您的账户已被踢下线,正在退出')
    setTimeout(() => {
      // eslint-disable-next-line
      logOut()
    }, 1500)
    // eslint-disable-next-line
  }, [])
  const receiveMsg = useCallback((e) => {
    e.data.forEach((item: any) => {
      if (!item.payload.data) return
      const data = JSON.parse(item.payload.data)
      if (data.Action === 'AccountNotify') {
        Toast.fail('您的账户已被封禁,正在退出')
        setTimeout(() => {
          // eslint-disable-next-line
          logOut()
        }, 1500)
      }
      if ((data.Action === 'RoomMessage' || data.Action === 'RoomAttentAnchor') && item.to === roomId) {
        if (
          item.from.startsWith("visitor_") == true && data.Data.chat.message != "进入直播间"
        ) {
          // 游客账号且发的其他消息
          console.log("垃圾信息");

        } else {
          showMsg(data);
        }


      }
      if (data.Action === 'RoomAttentAnchor') {
        showMsg(data)
      }
      // 更新主播信息
      if (data.Action === 'AnchorLiveInfoUpdate') {
        updateInfo()
      }

      if (data.Action === "SensitiveWordsUpdate") {
        const text = base64Decode(data.Data.sensitive_words);//解密
        const list = text.split('|')
        setItem('textFilter', JSON.stringify(list))
        dispatch(SetTextFilter(list))
      }
      // if (data.Action === 'LiveGroupMemberJoinExit') getMemberNum()
      // if (data.Action === 'GiftAnimation') this.$emit('pushGift', data)
    })
    // eslint-disable-next-line
  }, [showMsg, roomId, userInfo])


  const base64Decode = function (base64String: string) {
    const decodedData = window.atob(base64String);
    const decoder = new TextDecoder('utf-8');
    const decodedString = decoder.decode(new Uint8Array(
      Array.from(decodedData).map(char => char.charCodeAt(0))
    ));
    return decodedString;
  }

  const ready = useCallback(() => {
    tim.current.joinGroup({ groupID: roomId.toString() })
      .then(() => {
        setIsJoin(true);
        /* if (localStorage.getItem('uid')) {
          clearTimeout(sendTimer)
          // this.joinMsg = '加入直播间'
          sendTimer = setTimeout(() => {
            sendMsg('进入直播间', 'RoomMessage')
          }, 500)
        } */
      })
      .catch((e: any) => {
        setIsJoin(true);
        /* if (localStorage.getItem('uid')) {
          clearTimeout(sendTimer)
          // this.joinMsg = '加入直播间'
          sendTimer = setTimeout(() => {
            sendMsg('进入直播间', 'RoomMessage')
          }, 500)
        } */
      })
    // if (this.type === 'video') this.getMemberNum()
    // eslint-disable-next-line
  }, [])



  useEffect(() => {
    // @ts-ignore
    if (Number(window.localStorage.getItem('flag')) === 1) {
      setInterval(function () {   //这个定时器返回A页面会继续执行
        if (localStorage.reload.toString() === 'true') {  //判断是否刷新页面
          localStorage.setItem('flag', "0");
          window.location.reload()
        }
      }, 500)
    }
    if (!roomId) return

    tim.current = TIM.create({
      SDKAppID: sdkConfig.im_sdkappid
    })

    tim.current.setLogLevel(3)

    tim.current.off(TIM.EVENT.MESSAGE_RECEIVED, receiveMsg)
    tim.current.off(TIM.EVENT.SDK_READY, ready)
    tim.current.off(TIM.EVENT.KICKED_OUT, onKickedOut)

    tim.current.on(TIM.EVENT.MESSAGE_RECEIVED, receiveMsg)
    tim.current.on(TIM.EVENT.SDK_READY, ready)
    tim.current.on(TIM.EVENT.KICKED_OUT, onKickedOut)
    // 登录用户进入聊天室
    if (localStorage.getItem('uid')) {
      tim.current.login({ userID: localStorage.getItem('uid'), userSig: localStorage.getItem('txim_sign') })
    } else {
      // 游客进入聊天室
      let userName = `游客P${moment().format('YYYYMMDDHHmmss')}${parseInt(String(Math.random() * 100))}`
      http.post('/webapi/user/getImUserKey', { temp_uid: userName })
        .then((res) => {
          let txim = decryptAES(res.data.data);
          if (txim) {
            tim.current.login({ userID: txim.im_id, userSig: txim.txim_sign })
              .then(() => {
                // eslint-disable-next-line
                localStorage.setItem('userName', txim.im_id)
                // eslint-disable-next-line
                localStorage.setItem('txim_sign', txim.txim_sign)
              })

          }

        })
    }

    return () => {
      if (tim.current) {
        // if (type !== 'avc') {
        //   tim.current.quitGroup(roomId)
        // }
        tim.current.off(TIM.EVENT.MESSAGE_RECEIVED, receiveMsg)
        tim.current.off(TIM.EVENT.SDK_READY, ready)
        tim.current.off(TIM.EVENT.KICKED_OUT, onKickedOut)
        // eslint-disable-next-line
        tim.current.logout()
      }
    }
    // eslint-disable-next-line
  }, [roomId, userInfo, receiveMsg, onKickedOut, ready, sdkConfig, type, sendMsg, imClose])

  const changeInput = useCallback(() => {
    setInput(input.current.value)
  }, [input])


  useEffect(() => {
    if (localStorage.getItem('uid') && isJoin) {
      clearTimeout(sendTimer)
      // this.joinMsg = '加入直播间'
      sendTimer = setTimeout(() => {
        // eslint-disable-next-line
        sendMsg('进入直播间', 'RoomMessage')
      }, 500)
    }
    // eslint-disable-next-line
  }, [userInfo, isJoin])

  const submit = useCallback(async (e) => {
    e.preventDefault()
    if (!userInfo.id) {
      alert('您还未登录', '请登录使用完整功能', [
        { text: '取消', onPress: () => { } },
        { text: '确定', onPress: () => { window.location.replace('/login?page=' + window.location.pathname + window.location.search) } }
      ])
      return
    }

    if (
      Number(userInfo.user_level) < Number(sdkConfig.speak_level)
    ) {
      Toast.fail("您尚未达到发言等级,快去做任务吧～");
      return;
    }

    if (!inputMsg) {
      Toast.fail('请先输入消息')
      return
    }


    // 过滤
    /*  http.post('/webapi/textfilter/imDirtyWords', {
       content: inputMsg
     })
       .then(({ data: { data } }) => {
         sendMsg(data, 'RoomMessage')
       }) */


    sendMsg(await formateSensitiveWord(inputMsg), 'RoomMessage')
    // sendMsg(inputMsg, 'RoomMessage')



    // setItem(`${userInfo.id}${getDate(Date.now() / 1000, 'yy-mm-dd')}danmu`, '1')
    // eslint-disable-next-line
  }, [userInfo.id, sendMsg, inputMsg])

  useEffect(() => {
    if (isAttend) {
      sendMsg('关注了主播', 'RoomAttentAnchor')
    }
    document.body.addEventListener('touchmove', function (event) {
      event.preventDefault();
    });
    // eslint-disable-next-line
  }, [isAttend, sendMsg])
  return (
    <MessageWrap>
      <div ref={scroll} className="scroll">
        {/*<div className="announcement-wrap">*/}
        {/*  <img src={require('./announcement@2x.png')}/>*/}
        {/*  <p className="announce-title">公告</p>*/}
        {/*  <p className='announce-content'>{detail.signature}</p>*/}
        {/*</div>*/}
        <div className="msg-wrap">
          <p className="tips">{sdkConfig.room_notice}</p>
          {
            msgs.map((msg, index) => {
              // return <p className='msg-item' style={{ color: getLevelColor(msg.Data.chat.sender.user_level) }} key={index}><img src={LevelIcon[msg.Data.chat.sender.user_level]} alt=""/><span  style={{ color: getLevelColor(msg.Data.chat.sender.user_level) }}>{msg.Data.chat.sender.nick_name}：</span>{msg.Data.chat.message}</p>
              return <p className='msg-item' key={index}><img src={LevelIcon[msg.Data.chat.sender.user_level]} alt="" /><span>{msg.Data.chat.sender.nick_name}：</span>{msg.Data.chat.message}</p>

            })
          }
        </div>
      </div>

      <form className="form">
        <input value={inputMsg} onChange={changeInput} ref={input} type="text" maxLength={50} disabled={Number(imClose) === 1} placeholder={Number(imClose) === 1 ? '聊天功能已关闭' : '请输入消息'} />
        <button type='submit' onClick={submit} disabled={Number(imClose) === 1}>发送</button>
      </form>
    </MessageWrap>
  )
}

const mapState = (state: any) => ({
  sdkConfig: state.sdkConfig,
  userInfo: state.userInfo
})

export default connect(mapState, null)(Message)
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}

