import React, { useCallback, useEffect, useRef, useState } from "react";
import UserInfoWrap from "./style";
import BackHeader from "../../components/back-header/back-header";
import { RouteChildrenProps } from 'react-router-dom'
import { connect } from "react-redux";
import { Icon, Toast } from "antd-mobile";
import trans from "../../common/util/px";
import cos from '../../common/js/upload'
import { defaultAvatar } from "../../common/js/config";
import http from '../../common/js/http'
import { SetUserInfo } from "../../store/action";
import { uuid } from "../../common/util/uuid";

interface UserInfoProps extends RouteChildrenProps {
  userInfo: any
  sdkConfig: any
  changeUserInfo(info: any): void
}

const UserInfo: React.FC<UserInfoProps> = (props) => {
  const { userInfo, sdkConfig, changeUserInfo, history } = props

  const [nickName, setName] = useState<string>('')
  const [sign, setSign] = useState<string>('')
  const [avatar, setAvatar] = useState<string>('')
  const [avatarUrl, setAvatarUrl] = useState<string>('')

  const nameInput = useRef<any>()
  const signInput = useRef<any>()
  const fileInput = useRef<any>()

  useEffect(() => {
    setName(userInfo.nick_name)
    nameInput.current.value = userInfo.nick_name || ''
    setSign(userInfo.signature)
    signInput.current.value = userInfo.profile ? userInfo.profile.signature : ''
    setAvatar(userInfo.avatar)
    
  }, [userInfo])

  const changeName = useCallback((e) => {
    setName(nameInput.current.value)
  }, [])

  const changeSign = useCallback((e) => {
    setSign(signInput.current.value)
  }, [])

  const upload = useCallback(() => {
    const file = fileInput.current.files[0]
    if (file.type.indexOf('image') === -1) {
      Toast.fail('请选择图片文件')
      return
    }
    const filePath = uuid();
    const cosObj = cos()
    cosObj.putObject({
      Bucket: sdkConfig.cos_bucket,
      Region: sdkConfig.cos_region,
      Key: sdkConfig.cos_folder_image + `/${filePath}`,
      Body: file
    }, (err: any, data: any) => {
      if (err) {
        console.log(err)
        Toast.fail('上传失败')
        return
      }
      console.log(data.Location, 'sss', sdkConfig.cos_folder_image + `/${filePath}`);

      // setAvatar('https://' + data.Location)
      // setAvatar(`/${sdkConfig.cos_folder_image}/${filePath}`)
      setAvatar('https://' + data.Location)
      setAvatarUrl(`/${sdkConfig.cos_folder_image}/${filePath}`)
      console.log(`/${sdkConfig.cos_folder_image}/${filePath}`, avatar);

      fileInput.current.value = ''
    })
    // eslint-disable-next-line
  }, [sdkConfig])

  const submit = useCallback(() => {
    // 检查是否输入了昵称
    if (nickName.trim().length === 0) {
      console.log('1111');

      return Toast.fail("请输入昵称");
    }
    // 检查是否有空格
    if (nickName !== nickName.trim()) {
      console.log('222');
      return Toast.fail("您所提交的昵称不符合规则，请重新输入");
    }

    // 检查是否包含非中文、英文、数字的字符
    const regex = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/;
    if (!regex.test(nickName)) {
      console.log('333');
      return Toast.fail("只能输入包含(中文,数字,英文)");
    }

    // 检查是否超过10个字符
    /*  if (nickName.length > 10) {
 
       return "昵称不能超过10个字符";
     } */

    // 名字符合要求
    http.post('/webapi/User/editUserInfo', {
      avatar: avatarUrl,
      signature: sign,
      nick_name: nickName
    })
      .then(() => {
        changeUserInfo({
          ...userInfo,
          avatar,
          signature: sign,
          nick_name: nickName
        })
        history.goBack()
      })



    // eslint-disable-next-line
  }, [avatar, avatarUrl, nickName, sign, userInfo, changeUserInfo, history])
  const avatarError = useCallback(() => {
    setAvatar(defaultAvatar)
  }, [])
  return (
    <UserInfoWrap>
      <BackHeader title='个人资料' {...props} />
      <div className="input avatar">
        <label>头像</label>
        <div style={{ flex: 1 }} />
        <img onClick={() => { fileInput.current.click() }} src={avatar} alt="" onError={avatarError} />
        <Icon style={{ marginLeft: trans(27) }} color='#939393' type='right' />
      </div>
      <div className="input">
        <label>昵称</label>
        <input onChange={changeName} type="text" ref={nameInput} maxLength={10} />
        <Icon style={{ marginLeft: trans(27) }} color='#939393' type='right' />
      </div>
      <div className="input">
        <label>ID</label>
        <p>{userInfo.id}</p>
      </div>
      <div className="input" onClick={() => {
        if (userInfo.account) return
        history.push('/user-center/bind-phone')
      }}>
        <label>手机号</label>
        <span>{userInfo.account || '绑定手机号'}</span>
        <Icon style={{ marginLeft: trans(27) }} color='#939393' type='right' />
      </div>
      <div className="input">
        <label>个性签名</label>
        <input onChange={changeSign} type="text" ref={signInput} />
        <Icon style={{ marginLeft: trans(27) }} color='#939393' type='right' />
      </div>
      <div className="footer">
        <button onClick={submit}>保存</button>
        <p>严禁上传任何违反国家法律法规内容</p>
      </div>
      <input onChange={upload} type="file" ref={fileInput} style={{ display: 'none' }} />
    </UserInfoWrap>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo,
  sdkConfig: state.sdkConfig
})

const mapDispatch = (dispatch: any) => {
  return {
    changeUserInfo(info: any) {
      dispatch(SetUserInfo(info))
    }
  }
}

export default connect(mapState, mapDispatch)(UserInfo)
