import styled from 'styled-components'
import trans from '../../common/util/px'

const FilterList = styled.div`
  .head {
    display: flex;
    height: ${trans(90)};
    align-items: center;

    & > p {
      padding-left: ${trans(256)};
      font-size: ${trans(36)};
      color: #060606;
      font-weight: bold;
    }
  }

  .list {
    width: 100%;
    height: calc(100vh - ${trans(179)});
    overflow-y: scroll;

    .cas-item {
      margin-top: ${trans(40)};
      padding: 0 ${trans(30)};

      & > h5 {
        margin-bottom: ${trans(47)};
        font-size: ${trans(30)};
        color: #000000;
      }

      .race-list {
        display: flex;
        flex-wrap: wrap;

        & > p {
          margin-right: ${trans(52)};
          padding: 0 ${trans(22)};
          height: ${trans(50)};
          border-radius: ${trans(25)};
          line-height: ${trans(50)};
          background-color: #e8f3ff;
          margin-bottom: ${trans(47)};
          font-size: ${trans(24)};
          color: #0086ff;

          &.active {
            background-color: #0086ff;
            color: #fff;
          }
        }
      }
    }
  }

  .control {
    display: flex;
    height: ${trans(89)};
    padding-left: ${trans(29)};
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #F4F4F4;

    & > p {
      font-size: ${trans(24)};
      color: #625F63;

      &:first-child {
        margin-right: ${trans(49)};
        color: #000000;
      }
    }

    & > button {
      width: ${trans(152)};
      height: 100%;
      border: none;
      outline: none;
      background-color: #0086ff;
      font-size: ${trans(30)};
      color: #FFFFFF;
    }
  }
`

export default FilterList
