import React, { Fragment, useCallback } from 'react'
import { ScoreItemWrap } from './style'
import { getDate } from '../../common/util/transtime'
import trans from '../../common/util/px'
import {defaultAvatar} from "../../common/js/config";

interface ScoreProps {
  score: any
  index: number
  collection(index: number): void
}

const status: string[] = ['比赛异常', '未开赛', '上半场', '中场', '下半场', '加时赛', '加时赛', '点球对决', '完场', '推迟', '中断', '腰斩', '取消', '待定']

const ScoreItem: React.FC<ScoreProps> = ({ score, index, collection }) => {
  const focus = useCallback((e) => {
    e.preventDefault()
    collection(index)
  }, [collection, index])

  return (
    <ScoreItemWrap to={'/score/detail/' + score.id}>
      <div className="top">
        <div className='name'><p className="text-overflow">{score.matchevent ? score.matchevent.short_name_zh :'-'}</p><span>{getDate(score.time, 'mm-dd hh:mt')}</span></div>
        <p className='status'>{status[score.status]}</p>
        <div className="type">
          {score.live ? <img src={require('./pic_video@2x.png')} alt=""/> : null}
        </div>
      </div>
      <div className="center">
        <span className='text-overflow'>{score.hometeam ? score.hometeam.name_zh :'-'}</span>
        <span>{score.home_score}-{score.away_score}</span>
        <span className='text-overflow'>{score.awayteam ? score.awayteam.name_zh : '-'}</span>
      </div>
      <p className="bottom">
        <span className='yellow'>{score.home_yellowcard}</span>
        <span style={{ marginRight: trans(12) }} className='red'>{score.home_redcard}</span>
        <Fragment>半：{score.home_halfscore}-{score.away_halfscore} 角：{score.home_corner}-{score.away_corner}</Fragment>
        <span className='yellow'>{score.away_yellowcard}</span>
        <span className='red'>{score.away_redcard}</span>
      </p>
      {
        score.attented ? <img className='collection' onClick={focus} src={require('./ic_shoucang_pre@2x.png')} alt=""/> : <img onClick={focus} className='collection' src={require('./ic_shoucang@2x.png')} alt=""/>
      }
      <p className={score.status > 1 && score.status <= 7 ? "living" : "living end"}>{score.status > 1 && score.status <= 7 && <img src={require('./live@2x.png')} alt=""/>}{score.status > 1 && score.status <= 7 ? '直播中' : '未直播'}</p>
      <div className="avatars">
        {score.anchors && score.anchors.map((item: any) => <img key={item.id} src={item.avatar || defaultAvatar} alt=""/>)}
      </div>
    </ScoreItemWrap>
  )
}

export default ScoreItem
