import styled from 'styled-components'
import trans from '../../common/util/px'

const LiveDetailWrap = styled.div`
  .am-tabs-default-bar-tab-active {
    color: #0086ff;
  }

  .am-tabs-default-bar-underline {
    border: 1px #0086ff solid;;
  }

  //height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;

  .download-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${trans(10)} ${trans(20)};

    .logo {
      width: ${trans(80)};
      height: ${trans(80)};
    }

    .content {
      background: #ffffff;
      margin-left: ${trans(20)};
      font-size: ${trans(24)};
      color: #333333;
      line-height: 1.3;
    }

    .download-btn {
      height: ${trans(66)};
      width: ${trans(200)};
      border-radius: ${trans(10)};
      background-color: #319cfc;
      color: #fff;
      font-size: ${trans(26)};
      line-height: ${trans(66)};
      text-align: center;
    }
  }

  #player {
    width: 100%;
    height: ${trans(450)};
    background-color: #000;
  }

  .am-tabs-default-bar {
    width: ${trans(604)};
    overflow: hidden;
  }

  .focus {
    position: absolute;
    right: 0;
    top: ${trans(550)};
    height: 43px;
    padding: 0 ${trans(20)};
    outline: none;
    border: none;
    background-color: #0086ff;
    font-size: ${trans(26)};
    color: #FFFFFF;

    & > img {
      width: ${trans(30)};
      height: ${trans(26)};
      margin-right: ${trans(11)};
      vertical-align: top;
      margin-top: ${trans(2)};
    }

    &.active {
      opacity: 0.6;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #F4F7FF;
    display: flex;
    flex-direction: column;
    .announcement-wrap {
      display: flex;
      align-items: center;
      line-height: ${trans(36)};
      font-size: ${trans(28)};
      padding: ${trans(20)};
      background-color: #DDE6FF;
      
      img {
        width: ${trans(23)};
      }

      .announce-title {
        word-break: keep-all;
        color: #0086FF;
        margin-left: ${trans(5)};
      }

      .announce-content {
        color: #333333;
        line-height: 1.2;
        word-break: break-all;
      }
  }
    .anchor {
      width: ${trans(690)};
      margin: ${trans(109)} auto 0;
      border-radius: ${trans(10)};
      background-color: #fff;
      display: table;
      text-align: center;

      .avatar {
        display: block;
        width: ${trans(115)};
        height: ${trans(115)};
        border-radius: 50%;
        margin: -${trans(58)} auto 0;
      }

      .name {
        padding-top: ${trans(19)};
        padding-bottom: ${trans(12)};
        font-size: ${trans(28)};
        color: #333333;
      }

      .id {
        padding-bottom: ${trans(44)};
        font-size: ${trans(20)};
        color: #929292;
      }

      .center {
        display: flex;
        padding: 0 ${trans(196)};
        justify-content: space-between;
        margin-bottom: ${trans(54)};

        & > p {
          text-align: center;
          font-size: ${trans(30)};
          color: #333333;

          & > span {
            display: block;
            margin-top: ${trans(21)};
            font-size: ${trans(24)};
          }
        }
      }

      .r-title {
        margin-bottom: ${trans(31)};
        font-size: ${trans(24)};
        color: #757575;
      }

      .r-content {
        padding-bottom: ${trans(38)};
        font-size: ${trans(28)};
        color: #333333;
      }
    }
  }

  .go-home {
    position: fixed;
    right: ${trans(20)};
    bottom: ${trans(250)};
    width: ${trans(80)};
    height: ${trans(80)};
    line-height: ${trans(80)};
    border-radius: 50%;
    background: #0086ff;
    text-align: center;
    font-size: ${trans(26)};
    color: #fff;
  }

  .refresh-btn {
    position: fixed;
    right: ${trans(20)};
    bottom: ${trans(350)};
    border-radius: 50%;
    background: #0086ff;
    font-size: 0;
    padding: ${trans(15)};

    > img {
      width: ${trans(50)};
      height: ${trans(50)};
    }
  }
`

export default LiveDetailWrap
