import styled from 'styled-components'
import trans from '../../common/util/px'

const TabBarWrap = styled.div`
  background: rgba(0,0,0,0.6);
  position: fixed;
  left: ${trans(30)};
  right: ${trans(30)};
  bottom: ${trans(150)};
  border-radius: ${trans(30)};
  padding: ${trans(20)};
  .download-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-wrap {
      display: flex;
      align-items: center;
      .close {
          width: ${trans(50)};
          height: ${trans(50)};
        }
        .logo {
          margin-left: ${trans(20)};
          width: ${trans(80)};
          height: ${trans(80)};
        }
        .content {
          margin-left: ${trans(20)};
          font-size: ${trans(24)};
          color: #ffffff;
          line-height: 1.3;
        }
    }
    .download-btn {
      height:  ${trans(66)};
      width:  ${trans(200)};
      border-radius:  ${trans(10)};
      background-color: #319cfc;
      color: #fff;
      font-size:  ${trans(26)};
      line-height:  ${trans(66)};
      text-align: center;
    }
  }
`

export default TabBarWrap
