import styled from 'styled-components'
import trans from '../../common/util/px'

const ScoreHomeWrap = styled.div`
  .am-tabs-default-bar-tab-active {
    color: #0086ff;
  }
  .am-tabs-default-bar-underline {
    border: 1px #0086ff solid;;
  }
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: ${trans(96)};
  .header {
    display: flex;
    align-items: center;
    height: ${trans(90)};
    &>a>img {
      width: ${trans(35)};
      height: ${trans(38)};
      margin-left: ${trans(30)};
      margin-right: ${trans(239)};
    }
    &>p {
      font-weight: bold;
      font-size: ${trans(32)};
      color: #333333;
    }
  }
  .screen {
    position: fixed;
    left: ${trans(15)};
    top: ${trans(20)};
    z-index: 999;
    &>img {
      width: ${trans(35)};
      height: ${trans(38)};
    }
  }
  .content-wrap {
    width: 100%;
    height: 100%;
  }
`

export default ScoreHomeWrap
