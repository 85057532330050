enum Constants {
  InitConfig,
  SetCategories,
  SetUserInfo,
  SetIm,
  SetTextFilter
}

const constants = Constants
export default constants
