import React from "react";

interface AlertProps {
  type: number,
  mi: number
  close(): void
}

const MiAlert: React.FC<AlertProps> = ({type, mi, close}) => {
  return (
    <div className="mi-alert">
      <div className="box">
        <img className="title" src={type === 0 ? require('./bg_signin@2x.png') : require('./bg_receive@2x.png')} alt=""/>
        <p>获得<span>{mi}</span>鲸币</p>
        <div className="btn" onClick={close}>知道了</div>
        <img onClick={close} className="close" src={require('./close@2x.png')} alt=""/>
      </div>
    </div>
  )
}

export default MiAlert
