import React, {useEffect, useState, useCallback} from "react";
import http from '../../common/js/http'

interface RaceIndexProps {
  raceId: string
}

const company: any = {
  2: 'BET365',
  3: '皇冠',
  4: '10BET',
  5: '立博',
  6: '明陞',
  7: '澳彩',
  8: 'SNAI',
  9: '威廉希尔',
  10: '易胜博',
  11: '韦德',
  12: 'EuroBet',
  13: 'Inter wetten',
  14: '12bet',
  15: '利记',
  16: '盈禾',
  17: '18Bet',
  18: 'Fun88',
  19: '竞彩官方',
  21: '188',
  22: '平博'
}

const RaceIndex: React.FC<RaceIndexProps> = ({ raceId }) => {
  const [active, setActive] = useState<string>('asia')
  const [asia, setAsia] = useState<any>({})
  const [eu, setEu] = useState<any>({})
  const [bs, setBs] = useState<any>({})
  const [current, setCurrent] = useState<any>({})

  useEffect(() => {
    http.post('/webapi/football/matchOdd', { matchid: raceId })
      .then(({ data: { data } }) => {
        const res: any = {
          asia: {},
          eu: {},
          bs: {}
        }
        for (let key in data) {
          data[key].forEach((item: any) => {
            if (!res[key][item.company_id]) {
              res[key][item.company_id] = [item]
            } else {
              res[key][item.company_id].push(item)
            }
          })
          for (let company in res[key]) {
            res[key][company].sort((a: any, b: any) => a.change_time - b.change_time)
          }
        }
        // console.log(Object.keys(res['asia']))
        setAsia(res.asia)
        setEu(res.eu)
        setBs(res.bs)
        setCurrent(res.asia)
      })
  }, [raceId])

  const changeTab = useCallback((tab: string) => {
    setActive(tab)
    setCurrent(tab === 'asia' ? asia : tab === 'eu' ? eu : bs)
  }, [asia, eu, bs])

  return (
    <div className="race-index">
      <ul className="tabs">
        <li onClick={() => changeTab('asia')} className={active === 'asia' ? 'active' : ''}>让球</li>
        <li onClick={() => changeTab('eu')} className={active === 'eu' ? 'active' : ''}>欧赔</li>
        <li onClick={() => changeTab('bs')} className={active === 'bs' ? 'active' : ''}>总进球</li>
      </ul>
      <div className="table-head">
        <p>公司</p>
        <p></p>
        <p>主队</p>
        <p></p>
        <p>客队</p>
      </div>
      <div className="table">
        {
          Object.keys(current).map(item => {
            return <div key={item} className={"company"}>
              <div className="label"><span className="text-overflow">{company[item]}</span></div>
              <div className="data">
                <div className="top">
                  <p>初盘</p>
                  <p>{current[item][0].value1}</p>
                  <p>{current[item][0].value2}</p>
                  <p>{current[item][0].value3}</p>
                </div>
                {
                  current[item].length >= 2 ?
                  <div className="top">
                    <p>即时盘</p>
                    <p>{current[item][current[item].length - 1].value1}</p>
                    <p>{current[item][current[item].length - 1].value2}</p>
                    <p>{current[item][current[item].length - 1].value3}</p>
                  </div> : null
                }
              </div>
            </div>
          })
        }
      </div>
    </div>
  )
}

export default RaceIndex
