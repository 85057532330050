import React, {useCallback, useState} from 'react'
import TabBarWrap from './style'
// import { NavLink } from 'react-router-dom'

const DownloadBar: React.FC = () => {
    const [closeStatus, setCloseStatus] = useState<boolean>( true)
    const handleClose = useCallback(() => {
        setCloseStatus(false)
        // eslint-disable-next-line
    }, [closeStatus])
    const host = 'https://' + window.location.host.replace('m.','www.')
    return (
     closeStatus ? <TabBarWrap>
         <div className="download-wrap">
             <div className='left-wrap'>
                 <img className='close' alt='close' src={require("./close.png")} onClick={handleClose}/>
                 <img className='logo' alt="logo" src={require("./logo.png")} />
                 <div className='content'>
                     <div>鲸鱼直播</div>
                     <div>万名主播 等你来撩</div>
                 </div>
             </div>
             <a target={'_blank'} rel="noopener noreferrer" href={host + '/download'} className='download-btn' >
                 下载APP
             </a>
         </div>
     </TabBarWrap> : <div></div>
  )
}

export default DownloadBar
