import React, {Fragment, useCallback, useState} from "react";
import {Picker, Icon, Toast} from "antd-mobile";
import http from '../../common/js/http'

interface InputInfoProps {
  type: string
  history: any
}

const sexs = [
  { value: 0, label: '女' },
  { value: 1, label: '男' }
]

const constellations = [
  {
  value: '白羊座',
  label: '白羊座'
}, {
  value: '金牛座',
  label: '金牛座'
}, {
  value: '双子座',
  label: '双子座'
}, {
  value: '巨蟹座',
  label: '巨蟹座'
}, {
  value: '狮子座',
  label: '狮子座'
}, {
  value: '处女座',
  label: '处女座'
}, {
  value: '天秤座',
  label: '天秤座',
}, {
  value: '天蝎座',
  label: '天蝎座'
}, {
  value: '射手座',
  label: '射手座'
}, {
  value: '摩羯座',
  label: '摩羯座'
}, {
  value: '水瓶座',
  label: '水瓶座'
}, {
  value: '双鱼座',
  label: '双鱼座',
}]

interface Form {
  gender: number,
  age: string,
  height: string,
  weight: string,
  constellation: string,
  career: string,
  city: string,
  signature: string
}

const InputInfo: React.FC<InputInfoProps> = ({ type, history}) => {
  const [form, setForm] = useState<Form>({
    gender: 2,
    age: '',
    height: '',
    weight: '',
    constellation: '',
    career: '',
    city: '',
    signature: ''
  })

  const [intro, setIntro] = useState<string>('')

  const changeForm = useCallback((key: string, val: any) => {
    setForm({
      ...form,
      [key]: val
    })
  }, [form])

  const changeInput = useCallback((e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }, [form])

  const editInfo = useCallback(() => {
    http.post('/webapi/User/editUserInfo', { ...form })
      .then(() => {
        Toast.success('申请成功，请等待审核通过')
        history.goBack()
      })
  }, [form, history])

  const editDesc = useCallback(() => {
    if (!intro) {
      Toast.fail('请输入个人简介', 1, undefined, false)
      return
    }
    http.post('/webapi/auth/youliaoAuth', {
      introduction: intro
    })
      .then(() => {
        Toast.success('申请成功，请等待审核通过')
        history.goBack()
      })
  }, [intro, history])

  const submit = useCallback(() => {
    if (type === 'anchor') {
      editInfo()
    } else {
      editDesc()
    }
  }, [editInfo, editDesc, type])

  return (
    <div className="input-info">
      {
        type === 'anchor' ? <Fragment>
          <Picker data={sexs} cols={1} onOk={(val) => { changeForm('gender', val[0]) }}>
            <div className="input">
              <label>性别</label>
              <p style={{ color: form.gender === 2 ? '#B4B4B4' : '#666' }} className='type-select'>{form.gender === 1 ? '男' : form.gender === 0 ? '女' : '请选择性别'} <Icon color='#7F7F7F' type='right' /></p>
            </div>
          </Picker>
          <div className="input">
            <label>年龄</label>
            <input onChange={changeInput} name='age' value={form.age} type="text" placeholder='请输入年龄' />
          </div>
          <div className="input">
            <label>身高</label>
            <input onChange={changeInput} name='height' value={form.height} type="text" placeholder='请输入身高(cm)' />
          </div>
          <div className="input">
            <label>体重</label>
            <input onChange={changeInput} name='weight' value={form.weight} type="text" placeholder='请输入体重(kg)' />
          </div>
          <Picker data={constellations} cols={1} onOk={(val) => { changeForm('constellation', val[0]) }}>
            <div className="input">
              <label>星座</label>
              <p style={{ color: !form.constellation ? '#B4B4B4' : '#666' }} className='type-select'>{form.constellation || '请选择星座'} <Icon color='#7F7F7F' type='right' /></p>
            </div>
          </Picker>
          <div className="input">
            <label>职业</label>
            <input onChange={changeInput} name='career' value={form.career} type="text" placeholder='请输入职业' />
          </div>
          <div className="input">
            <label>城市</label>
            <input onChange={changeInput} name='city' value={form.city} type="text" placeholder='请输入城市' />
          </div>
          <div className="sign">
            <h6>个性签名</h6>
            <input onChange={changeInput} name='signature' value={form.signature} type="text" placeholder='请编辑个性签名'/>
          </div>
        </Fragment> : <textarea onChange={(e) => { setIntro(e.target.value) }} className='user-desc' placeholder='请编辑个人简介，个人简介将显示在你的有料号主页' />
      }
      <button onClick={submit}>提交申请</button>
    </div>
  )
}

export default InputInfo