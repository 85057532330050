import styled from 'styled-components'
import trans from '../../common/util/px'

const ListBody = styled.div`
  box-sizing: border-box;
  padding: ${trans(30)};
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
  background: #F4F4F4;
  min-height: calc(100vh -  ${trans(180)});
`

export default ListBody
