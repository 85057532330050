// eslint-disable-next-line
import { Carousel } from 'antd-mobile';
// eslint-disable-next-line
import React, { useCallback, useState, useEffect, useRef } from 'react'
// eslint-disable-next-line
import ReactDOM from "react-dom";
// eslint-disable-next-line
import { Link, RouteChildrenProps } from 'react-router-dom'
// eslint-disable-next-line
import trans from '../../common/util/px'
import http from '../../common/js/http';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// eslint-disable-next-line
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import DownloadWrap from './style'
import { connect } from "react-redux";

interface DownloadProps {
    sdkConfig?: any
    type?: any
}


const Download: React.FC<DownloadProps> = ({ sdkConfig, type }) => {

    const [banners, setBanner] = useState<any[]>([])
    const [isIos, setIsIos] = useState<boolean>(false)
    const [isWeChat, setIsWeChat] = useState<boolean>(false)
    const [isActive, setIsActive] = useState<number>(1);


    const go = () => {
        console.log(sdkConfig, 'sdkConfig');
        if (Number(type) === 2) {
            window.location.href = "https://gkdxsh2.bcxl.cn/3XkGTF"
        } else {
            if (isIos) {
                window.location.href = sdkConfig.dl_ios_tf
            } else {
                window.location.href = sdkConfig.dl_android
            }
        }
    }





    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.includes('micromessenger')) {
            // 包含 'micromessenger' 字符串，说明是微信浏览器
            setIsWeChat(true)
        }

        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        const isAndroid = /Android/i.test(navigator.userAgent);

        if (isIOS) {
            console.log('当前设备是iOS');
            setIsIos(true);
        } else if (isAndroid) {
            console.log('当前设备是Android');
            setIsIos(false);
        } else {
            console.log('当前设备不是iOS也不是Android');
        }


        http.post("/webapi/banner/list").then(({ data: { data } }) => {
            setBanner(data);
        });
    }, [])


    return (
        <DownloadWrap className='download_wrap'>
            <div className="download-box">
                <div
                    className="banner-wrap"
                    onTouchStart={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {banners.length ? (
                        <Swiper
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                            }}
                            loop={true}
                        >
                            {banners.map((item: any) => {
                                return (
                                    <SwiperSlide key={item.id}>
                                        <a
                                            href={item.jump_url}
                                            className="banner-item"
                                            style={{ backgroundImage: `url(${item.image_url})` }}
                                        >
                                            {""}
                                        </a>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    ) : null}
                </div>
                <div className="appLogo">
                    <div className="logo">
                        <img src={require('../live-detail/logo.png')} alt="" />
                    </div>
                    <div className="col">
                        <div className="top">
                            <div className="title">鲸鱼直播</div>
                            <div className="info">万名主播，等你来撩</div>
                        </div>
                        <div className="btn" onClick={go}>安装</div>
                    </div>

                </div>
                <div className="score_area">
                    <div className="score_box">
                        <img src={require("./iosbg_start.png")} alt="" />
                    </div>
                </div>
                <div className="course_area">
                    <p className="main-title"> {isIos ? 'IOS安装教程' : 'Android安装教程'}</p>
                    <p className="main-tip">{isIos ? 'APP下载完成后，需进行信任设置才可使用' : 'APP安装后若出现问题，查看相应教程'} </p>
                    {

                        !isIos ? <div className="course_box" >
                            <div className="ul">
                                <span onClick={() => setIsActive(1)} className={isActive === 1 ? 'active' : ''} >华为</span>
                                <span onClick={() => setIsActive(2)} className={isActive === 2 ? 'active' : ''}>OPPO</span>
                                <span onClick={() => setIsActive(3)} className={isActive === 3 ? 'active' : ''}>VIVO</span>
                                <span onClick={() => setIsActive(4)} className={isActive === 4 ? 'active' : ''}>小米</span>
                                <span onClick={() => setIsActive(5)} className={isActive === 5 ? 'active' : ''}>魅族</span>
                                <span onClick={() => setIsActive(6)} className={isActive === 6 ? 'active' : ''}>鸿蒙</span>
                            </div>

                            <div className="course_content" >
                                {isActive === 1 ? <img className="img" src={require('./img/course_huawei.png')} alt="" /> : null}
                                {isActive === 2 ? <img className="img" src={require('./img/course_oppo.png')} alt="" /> : null}
                                {isActive === 3 ? <img className="img" src={require('./img/course_vivo.png')} alt="" /> : null}
                                {isActive === 4 ? <img className="img" src={require('./img/course_xiaomi.png')} alt="" /> : null}
                                {isActive === 5 ? <img className="img" src={require('./img/course_meizu.png')} alt="" /> : null}
                                {isActive === 6 ? <img className="img" src={require('./img/course_hongmeng.png')} alt="" /> : null}

                            </div>
                        </div> :
                            <div className='course_content'>
                                <img className="img" src={require('./img/ios.png')} alt="" />
                            </div>
                    }


                </div>


                {isWeChat ? <div className="model">
                    <img src={require('./img/wechat_tip.png')} alt="" />
                </div> : null}
            </div>

        </DownloadWrap>
    );
}
const mapState = (state: any) => ({
    sdkConfig: state.sdkConfig,
})

export default connect(mapState, null)(Download);