
import CryptoJS from "crypto-js";

// 密钥和向量
const iv = CryptoJS.enc.Utf8.parse("1234567891234567"); // 初始化向量
const key = CryptoJS.enc.Utf8.parse("1234567812345678"); // 密钥


export const decryptAES = (encryptedText:string)=> {
  try {
    // Base64 解码密文
    const encryptedHexStr = CryptoJS.enc.Base64.parse(encryptedText);
    const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr);

    // AES 解密
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // 将解密后的内容转换为字符串
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    const jsonRes = JSON.parse(decryptedText);
    if (jsonRes) {
      return jsonRes;
    } else {
      return null;
    }
  } catch (error) {
    console.error("解密失败:", error);
    return null;
  }
}