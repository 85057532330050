import React, {useCallback, useRef, useState, Fragment} from "react";
import BindPhoneWrap from "../bind-phone/style";
import {SetUserInfo} from "../../store/action";
import { connect } from "react-redux";
import trans from "../../common/util/px";
import http from '../../common/js/http'
import {Toast} from "antd-mobile";

interface BindPhoneProps {
  next(step: number): void
  userInfo: any
  changeUserInfo(info: any): void
}

const BindPhone: React.FC<BindPhoneProps> = ({ next, userInfo, changeUserInfo }) => {
  const [phone, setPhone] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [pwd, setPwd] = useState<string>('')
  const [sec, setSec] = useState<number>(60)

  const timer = useRef<any>()

  const changePhone = useCallback((e: any) => {
    setPhone(e.target.value)
  }, [])

  const changeCode = useCallback((e: any) => {
    setCode(e.target.value)
  }, [])

  const changePwd = useCallback((e: any) => {
    setPwd(e.target.value)
  }, [])

  const sendCode = useCallback(() => {
    if (!/^1\d{10}$/.test(phone)) {
      Toast.fail('请输入手机号', 1, undefined, false)
      return
    }
    http.post('/webapi/user/sendVerifyCode', {
      mobile: phone
    })
      .then(() => {
        Toast.success('发送成功')
        let sec = 60
        timer.current = setInterval(() => {
          setSec(--sec)
          if (sec <= 0) {
            setSec(60)
            clearInterval(timer.current)
            timer.current = undefined
            return
          }
        }, 1000)
      })
  }, [phone])

  const submit = useCallback(() => {
    if (userInfo.account) {
      next(2)
      return
    }
    if (!phone) {
      Toast.fail('请输入手机号', 1, undefined, false)
      return
    }
    if (!pwd) {
      Toast.fail('请输入密码', 1, undefined, false)
      return
    }
    if (!code) {
      Toast.fail('请输入验证码', 1, undefined, false)
      return
    }
    http.post('/webapi/User/bindPhone', {
      mobile: phone,
      smscode: code,
      pwd
    })
      .then(() => {
        Toast.success('绑定成功', 1)
        changeUserInfo({
          ...userInfo,
          phone
        })
        next(2)
      })
  }, [phone, code, pwd, userInfo, changeUserInfo, next])

  return (
    <BindPhoneWrap>
      <div style={{ height: trans(104) }} />
      {
        !userInfo.account ? <Fragment>
          <div className="input">
            <input onChange={changePhone} value={phone} type="text" placeholder='请输入手机号'/>
          </div>
          <div className="input">
            <input onChange={changePwd} value={pwd} type="password" placeholder='请设置登录密码'/>
          </div>
          <div className="input">
            <input onChange={changeCode} value={code} type="text" placeholder='请输入手机验证码'/>
            <button onClick={sendCode}>{sec < 60 ? `${sec}s` : '获取验证码'}</button>
          </div>
        </Fragment> : <div className='phone-binded'>
          <img src={require('./bg@2x.png')} alt=""/>
          <p>您的账号已绑定手机号，可直接进入下一步。</p>
        </div>
      }
      <button style={{ marginTop: trans(86) }} onClick={submit}>下一步</button>
    </BindPhoneWrap>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo
})

const mapDispatch = (dispatch: any) => {
  return {
    changeUserInfo(info: any) {
      dispatch(SetUserInfo(info))
    }
  }
}

export default connect(mapState, mapDispatch)(BindPhone)