import styled from 'styled-components'
import trans from '../../common/util/px'

const UserInfoWrap = styled.div`
  height: 100vh;
  background-color: #F4F4F4;
  .input {
    display: flex;
    align-items: center;
    height: ${trans(119)};
    padding: 0 ${trans(30)};
    background-color: #fff;
    border-top: 1px solid #F4F4F4;
    &.avatar {
      height: ${trans(171)};
    }
    &>label {
      font-size: ${trans(30)};
      color: #666666;
      white-space: nowrap;
    }
    &>img {
      width: ${trans(120)};
      height: ${trans(120)};
      border-radius: 50%;
    }
    &>input {
      flex: 1;
      padding-left: ${trans(150)};
      outline: none;
      border: none;
      font-size: ${trans(30)};
      color: #333333;
      text-align: right;
    }
    &>p {
      flex: 1;
      padding-right: ${trans(72)};
      font-size: ${trans(30)};
      color: #333333;
      text-align: right;
    }
    &>span {
      flex: 1;
      font-size: ${trans(30)};
      color: #333333;
      text-align: right;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: ${trans(36)};
    &>button {
      display: block;
      width: ${trans(364)};
      height: ${trans(75)};
      margin: 0 auto;
      margin-bottom: ${trans(53)};
      border-radius: ${trans(37)};
      background-color: #0086ff;
      font-size: ${trans(32)};
      color: #fff;
      outline: none;
      border: none;
    }
    &>p {
      text-align: center;
      font-size: ${trans(24)};
      color: #787878;
    }
  }
`

export default UserInfoWrap
