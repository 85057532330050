export const formateSensitiveWord = (fText: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        var value = fText
        let array = JSON.parse(window.localStorage.getItem('textFilter') || '[]')

        console.log(array, 'array');

        array.forEach((e: string, i: number) => {
            var reg = new RegExp(e, "g");
            //判断内容中是否包括敏感词
            if (fText.indexOf(e) > -1) {
                console.log('是敏感词----', fText);
                // 将敏感词替换为 **
                value = value.replace(reg, "**");
            }
        });
        console.log(value, 'value');
        resolve(value);

        /*  getSensitiveword(token).then((res: any) => {
             if (res.code == 200) {
                 const sensitiveword = res.data;
                 var value = fText;
               
             }
 
         }) */



    });
};