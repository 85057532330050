import React, { useCallback, useEffect, useRef, useState } from "react";
import AnchorFocusWrap from "./style";
import BackHeader from "../../components/back-header/back-header";
import { RouteChildrenProps } from 'react-router-dom'
import ScrollWrap from "../../components/scroll-wrap/scroll-wrap";
import BottomLoading from "../../components/bottom-loading/bottom-loading";
import http from '../../common/js/http'
import { defaultAvatar } from "../../common/js/config";
// eslint-disable-next-line
import { Item } from "antd-mobile/lib/tab-bar";

const SIZE = 15

const AnchorFocus: React.FC<RouteChildrenProps> = (props) => {
  const [list, setList] = useState<any[]>([])
  const [showLoading, setLoading] = useState<boolean>(false)

  const page = useRef<number>(1)
  const copyList = useRef<any[]>([])
  const [showDefaultAvatar, setShowDefaultAvatar] = useState<Boolean>(false)

  const getList = useCallback((loading = false) => {
    return http.post('/webapi/Anchor/getAttentAnchors', {
      size: SIZE,
      page: page.current,
      hideLoading: loading
    })
      .then(({ data: { data } }) => {
        setLoading(data.list.length >= SIZE)
        copyList.current = [...copyList.current, ...data.list]
        setList([...copyList.current])
      })
  }, [])

  useEffect(() => {
    getList()
  }, [getList])

  const attending = useCallback((item: any, index: number) => {
    http.post('/webapi/Anchor/attentAnchor', {
      anchorid: item.id,
      type: Number(!item.isattent)
    })
      .then(() => {
        const copy = JSON.parse(JSON.stringify(list))
        copy[index].isattent = Number(!item.isattent)
        setList(copy)
      })
  }, [list])

  const loadMore = useCallback(() => {
    if (!showLoading) return Promise.resolve()
    page.current++
    return getList(true)
  }, [showLoading, getList])
  const avatarError = useCallback(() => {
    setShowDefaultAvatar(true)
  }, [setShowDefaultAvatar])
  return (
    <AnchorFocusWrap>
      <BackHeader title='关注主播' {...props} />
      <div className="list">
        <ScrollWrap loadMore={loadMore}>
          {
            list.map((item, index) => {
              return (
                <div className="anchor" key={item.id}>
                  {
                    showDefaultAvatar ? <img src={defaultAvatar} alt="" /> : <img src={item.anchor.avatar || defaultAvatar} alt="" onError={avatarError} />
                  }
                  {/* <img src={item.avatar || defaultAvatar} alt=""/> */}
                  <div className="center">
                    <h4>{item.anchor.nick_name}</h4>
                    <p>{item.anchor.signature}</p>
                  </div>
                  <button onClick={() => { attending(item, index) }} className={item.anchor.isattent ? 'active' : ''}>{item.anchor.isattent ? '已关注' : '+关注'}</button>
                </div>
              )
            })
          }
          {showLoading && <BottomLoading />}
        </ScrollWrap>
      </div>
    </AnchorFocusWrap>
  )
}

export default AnchorFocus