import styled from 'styled-components'
import trans from '../../common/util/px'
import bgImg from './bg@2x.png'

const RaceDetailWrap = styled.div`
  .am-tabs-default-bar-tab-active {
    color: #0086ff;
  }
  .am-tabs-default-bar-underline {
    border: 1px #0086ff solid;
    background: #0086ff;
  }
  height: 100%;
  overflow: hidden;
  .animation-wrap {
    position: relative;
    width: 100%;
    height: ${trans(497)};
    background: url(${bgImg}) center center no-repeat;
    background-size: cover;
    .player {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    width: 100%;
    height: 100%;
  }
  .am-tabs {
    height: calc(100% - ${trans(497)});;
  }
  .anchor-item {
    margin: 0 ${trans(30)};
    margin-bottom: ${trans(60)};
    display: flex;
    align-items: center;
    .avatar {
      width: ${trans(75)};
      height: ${trans(75)};
      border-radius: 50%;
    }
    .center {
      width: calc(100% - ${trans(212)});
      padding: 0 ${trans(20)};
      .top {
        margin-bottom: ${trans(9)};
        display: flex;
        align-items: center;
        &>p {
          max-width: 50%;
          margin-right: ${trans(20)};
          font-size: ${trans(28)};
          color: #000;
        }
        &>span {
          display: flex;
          align-items: center;
          font-size: ${trans(22)};
          color: #F2791E;
          &>img {
            margin-right: ${trans(7)};
            width: ${trans(15)};
            height: ${trans(19)};
          }
        }
      }
      .bottom {
        width: 99%;
        font-size: ${trans(26)};
        color: #929292;
      }
    }
    .btn {
      width: ${trans(137)};
      height: ${trans(53)};
    }
  }
  .race-index {
    padding: ${trans(17)} ${trans(30)};
    .tabs {
      display: flex;
      &>li {
        flex: 1;
        box-sizing: border-box;
        height: ${trans(62)};
        line-height: ${trans(62)};
        border: 1px solid #0086FF;
        text-align: center;
        font-size: ${trans(30)};
        color: #0086FF;
        border-right: none;
        &:last-child {
          border-right: 1px solid #0086FF;
        }
        &.active {
          background: #0086FF;
          color: #fff;
        }
      }
    }
    .table-head {
      display: flex;
      margin-top: ${trans(35)};
      &>p {
        flex: 1;
        height: ${trans(60)};
        line-height: ${trans(60)};
        text-align: center;
        font-size: ${trans(26)};
        color: #666666;
        &:first-child {
          flex: none;
          width: ${trans(115)};
        }
      }
    }
    .table {
      .company {
        display: flex;
        border: 1px solid #EFEFEF;
        border-bottom: none;
        &:last-child {
          border-bottom: 1px solid #EFEFEF;
        }
        .label {
          width: ${trans(115)};
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: ${trans(20)};
          color: #666666;
          background: #FFF0E7;
          &>span {
            width: ${trans(115)};
            text-align: center;
          }
        }
        .data {
          flex: 1;
          .top {
            display: flex;
            &>p {
              flex: 1;
              height: ${trans(43)};
              line-height: ${trans(43)};
              text-align: center;
              font-size: ${trans(20)};
              color: #666666;
            }
          }
        }
      }
    }
  }
  .go-home {
    position: fixed;
    right: ${trans(20)};
    bottom: ${trans(250)};
    width: ${trans(80)};
    height: ${trans(80)};
    line-height: ${trans(80)};
    border-radius: 50%;
    background: #0086ff;
    text-align: center;
    font-size: ${trans(26)};
    color: #fff;
  }
`

export const RaceDescWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: ${trans(24)} ${trans(29)};
  box-sizing: border-box;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .back {
      width: ${trans(49)};
      height: ${trans(49)};
    }
    .collect {
      width: ${trans(49)};
      height: ${trans(49)};
    }
    .center {
      text-align: center;
      color: #fff;
      font-size: ${trans(30)};
      &>p:last-child {
        margin-top: ${trans(18)};
        font-size: ${trans(24)};
        color: #FFFFFF;
      }
    } 
  }
  .status {
    margin-top: ${trans(42)};
    text-align: center;
    font-size: ${trans(24)};
    color: #FFFFFF;
  }
  .team-wrap {
    display: flex;
    align-items: center;
    margin-top: ${trans(23)};
    .team {
      flex: 1;
      font-size: ${trans(28)};
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      &>img {
        width: ${trans(118)};
        height: ${trans(118)};
        border-radius: 50%;
      }
      &>p {
        margin-top: ${trans(21)};
      }
      &:first-child {
        align-items: flex-end;
        text-align: center;
      }
      &:last-child {
        align-items: flex-start;
        text-align: center;
      }
    }
    .score {
      margin: 0 ${trans(66)};
      font-size: ${trans(48)};
      color: #FFFFFF;
    }
  }
  &>button {
    display: block;
    padding: 0 ${trans(20)};
    margin: 0 auto;
    margin-top: ${trans(44)};
    height: ${trans(40)};
    border-radius: ${trans(20)};
    background-color: rgba(0, 0, 0, 0.4);
    font-size: ${trans(24)};
    color: #FFFFFF;
    border: none;
    outline: none;
  }
`

export default RaceDetailWrap
