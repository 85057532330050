import React, {DetailedHTMLProps, useCallback, useRef, useState, useMemo, useEffect} from 'react'
import {RouteChildrenProps, Link} from 'react-router-dom'
import {Dispatch} from 'redux'
import { connect } from 'react-redux'
import RegWrap from './style'
import {SetUserInfo} from "../../store/action";
import {Icon, Toast} from 'antd-mobile'
import http from '../../common/js/http'
import {setItem} from "../../common/js/storage";

interface LoginProps extends RouteChildrenProps {
  setUserInfo(data: any): void
}

const Register: React.FC<LoginProps> = ({ history, setUserInfo, match }) => {
  const [pwd, setPwd] = useState<string>('')
  const [account, setAccount] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [countNum, setCount] = useState<number>(60)
  const [timer, setTimer] = useState<any>(null)
  const [type, setType] = useState<string>('register')

  useEffect(() => {
    if (match!.path.indexOf('register') >= 0) {
      setType('register')
    } else {
      setType('forget')
    }

    return () => clearInterval(timer)
  }, [match, timer])

  const phoneRef = useRef<DetailedHTMLProps<any, any>>()
  const passwordRef = useRef<DetailedHTMLProps<any, any>>()
  const codeRef = useRef<DetailedHTMLProps<any, any>>()

  const countText = useMemo(() => {
    if (countNum < 60 && countNum > 0) return `${countNum}s`
    return '获取验证码'
  }, [countNum])

  const changePhone = useCallback(() => {
    setAccount(phoneRef.current.value)
  }, [phoneRef])

  const changeCode = useCallback(() => {
    setCode(codeRef.current.value)
  }, [codeRef])

  const changePaw = useCallback(() => {
    setPwd(passwordRef.current.value)
  }, [passwordRef])

  const sendMsg = useCallback(() => {
    if (timer) return
    if (!/^1\d{10}$/.test(account)) {
      Toast.fail('请输入正确的手机号', 1, undefined, false)
      return
    }
    http.post('/webapi/user/sendCode', {
      mobile: account
    })
      .then(() => {
        Toast.success('验证码发送成功', 1, undefined, false)
        let total = countNum
        let timer = setInterval(() => {
          if (total <= 0) {
            clearInterval(timer)
            setTimer(null)
            setCount(60)
            return
          }
          setCount(--total)
        }, 1000)
        setTimer(timer)
      })
  }, [account, setCount, countNum, timer])

  const submit = useCallback(() => {
    if (!account) {
      Toast.fail('请输入正确的手机号', 1, undefined, false)
      return
    }
    if (!code) {
      Toast.fail('请输入验证码', 1, undefined, false)
      return
    }
    if (!pwd) {
      Toast.fail('请输入密码', 1, undefined, false)
      return
    }
    const url = type === 'register' ? '/webapi/user/regist' : '/webapi/user/changePwd'
    const data: any = {
      pwd,
      smscode: code
    }
    if (type === 'register') {
      data.account = account
    } else {
      data.mobile = account
    }
    http.post(url, data)
      .then(({ data: { data } }) => {
        history.goBack()
        if (type === 'register') {
          setItem('uid', data.id)
          setItem('token', data.token)
          setItem('nick_name', data.nick_name)
          setUserInfo(data)
        }
      })
  }, [pwd, account, setUserInfo, code, history, type])

  return (
    <RegWrap>
      <div className="head">
        <Icon type='left' size='lg' onClick={() => {history.goBack()}} />
      </div>
      <h3>{ type === 'register' ? '手机号注册' : '忘记密码' }</h3>
      <div className="phone">
        <p className="phone-pre">+86</p>
        <input onChange={changePhone} ref={phoneRef} type="text" placeholder='请输入11位手机号' />
      </div>
      <div className="input">
        <input onChange={changeCode} ref={codeRef} type="text" placeholder='请输入验证码'/>
        <button onClick={sendMsg}>{countText}</button>
      </div>
      <div className="input">
        <input ref={passwordRef} onChange={changePaw} type="password" placeholder='请输入密码'/>
      </div>
      <button onClick={submit} className="submit">{type === 'register' ? '下一步' : '登录'}</button>
      {type === 'register' ? <Link to='/login' className='login'>已有账号，立即登录</Link> : null}
    </RegWrap>
  )
}

const mapAction = (dispatch: Dispatch) => {
  return {
    setUserInfo(info: any) {
      dispatch(SetUserInfo(info))
    }
  }
}

export default connect(mapAction, null)(Register)
