import styled from 'styled-components'
import trans from '../../common/util/px'

const RecWrap = styled.div`
  .icon-search {
    height: ${trans(100)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    &>img {
      height: 100%;
    }
  }
  .banner-wrap {
    width: 100%;
    height: ${trans(320)};
    align-items: center;
  }
  .my-carousel {
    height: 100% !important;
  }
  .banner-item {
    display: block;
    width: 100%;
    height: ${trans(320)};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .sub-title {
    margin-bottom: ${trans(35)};
    display: flex;
    align-content: center;
    font-size: ${trans(30)};
    color: #333333;
    &>i {
      width: ${trans(5)};
      height: ${trans(27)};
      margin-right: ${trans(11)};
      border-radius: ${trans(5)};
      background-color: #0086ff;
    }
  }
  .anchors {
    padding: ${trans(26)} ${trans(26)} 0;
    box-sizing: border-box;
    border-bottom: ${trans(15)} solid #F4F4F4;
    .list {
      width: 100%;
      overflow-x: auto;
      padding-bottom: ${trans(26)};
      margin-top: ${trans(28)};
      display: flex;
      &>a {
        display: block;
        margin-right: ${trans(20)};
        width: ${trans(120)};
        &:last-child {
          margin-right: 0;
        }
        &>p {
          margin-top: ${trans(17)};
          text-align: center;
          font-size: ${trans(24)};
          color: #333333;
        }
      }
      .icon {
        width: ${trans(120)};
        height: ${trans(120)};
        margin: 0 auto;
        border-radius: 50%;
        border: ${trans(3)} solid red;
        position: relative;
        
        .avatar {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .voice {
          border-radius: ${trans(28)};
          padding-right:${trans(5)};
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -2px;
          line-height: ${trans(28)};
          font-size: ${trans(18)};
          color: #ffffff;
          display: flex;
          align-items: center;
          white-space: nowrap;
          background: #0086ff;
          &.active {
            background: #ff1356;
          }
          img {
            width: ${trans(28)};
            height: ${trans(28)};
            margin-right: ${trans(5)};
            background: white;
            border-radius: ${trans(28)};
          }
        }
      }
    }
  }
  .hot {
    
    padding: ${trans(40)} 0 ${trans(30)};
    border-bottom: ${trans(15)} solid #F4F4F4;
    &.no-border {
      padding-bottom: 0;
      border-bottom: none;
    }
    &:last-child {
      border-bottom: none;
    }
    .hot-list {
      padding: 0 ${trans(30)} 0 ${trans(22)};
      background: #F4F4F4;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  .race-carousel {
    width: 100%;
    border-bottom: ${trans(15)} solid #F4F4F4;
    .race-banner {
      display: flex;
      position: relative;
      .dot {
        position: absolute;
        right: ${trans(30)};
        top: ${trans(116)};
        width: ${trans(24)};
        height: ${trans(76)};
      }
      .race {
        width: 50%;
        &:first-child {
          border-right: 1px solid #F4F4F4;
        }
        .title {
          line-height: ${trans(35)};
          display: flex;
          justify-content: space-between;
          padding: 0 ${trans(30)};
          padding-top: ${trans(30)};
          white-space: nowrap;
          &>span {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: ${trans(26)};
            color: #828282;
            &:first-child {
              width: 60%;
            }
            &:last-child {
              width: 40%;
              text-align: right;
              font-size: ${trans(24)};
            }
          }
        }
        .team {
          line-height: ${trans(35)};
          margin: ${trans(35)} 0;
          display: flex;
          padding: 0 ${trans(30)};
          align-items: center;
          font-size: ${trans(28)};
          color: #000000;
          &>img {
            width: ${trans(50)};
            height: ${trans(50)};
            border-radius: 50%;
          }
          &>p {
            flex: 1;
          }
        }
      }
    }
  }
`

export default RecWrap
