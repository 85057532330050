import React, { useEffect, useState, useCallback, useRef } from 'react'
import ListBody from './style'
import http from '../../common/js/http'
import LiveItem from '../../components/live-item/live-item'
import BottomLoading from '../../components/bottom-loading/bottom-loading'

interface ListProps {
  type: string
}

const size = 16

const LiveList: React.FC<ListProps> = ({ type }) => {
  const [list, setList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isFinished, setFinished] = useState<boolean>(true)

  const page = useRef<number>(1)

  const getList = useCallback((hideLoading: boolean = false) => {

    // @ts-ignore
    // eslint-disable-next-line
    if(type == -1) {
      return http.post('/webapi/home/get_all', {
        size,
        page: page.current,
        hideLoading
      })
        .then(({ data: { data } }) => {
          setLoading(false)
          setFinished(data.list.length < size)
          setList(list.concat(data.list))
        })
    } else {
      return http.post('/webapi/live/getListByCategory', {
        categoryid: type,
        size,
        page: page.current,
        hideLoading
      })
          .then(({ data: { data } }) => {
            setLoading(false)
            setFinished(data.list.length < size)
            setList(list.concat(data.list))
          })
    }

  }, [list, page, type])

  const loadMore = useCallback(() => {
    if (loading) return
    setLoading(true)
    page.current++
    getList(true)
  }, [loading, getList, page])

  useEffect(() => {
    getList()
    // eslint-disable-next-line
  }, [])

  return (
    <ListBody>
      {
        list.map((item: any, index) => {
          return <LiveItem live={item} key={index} />
        })
      }
      {!isFinished ? <BottomLoading loadMore={loadMore} /> : null}
      <div style={{height: '10px', width: '100%'}}></div>
    </ListBody>
  )
}

export default LiveList
