import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RouteChildrenProps, Link } from 'react-router-dom'
import LiveDetailWrap from './style'
import http from '../../common/js/http'
import DPlayer from 'dplayer'
import { Tabs, Toast, Modal } from 'antd-mobile'
import { connect } from 'react-redux'
import Message from '../../components/message/message'
import { defaultAvatar } from '../../common/js/config'
import DetailRank from '../../components/detail-rank/detail-rank'
import '../score-home/height.css'
import { setItem, getItem } from "../../common/js/storage";
import { getDate } from "../../common/util/transtime";
// eslint-disable-next-line
import trans from "../../common/util/px";

const alert = Modal.alert

interface LiveDetailProps extends RouteChildrenProps {
  userInfo: any
}

const tabs = [
  {
    key: '0',
    title: <div>聊天</div>
  }, {
    key: '1',
    title: <div>主播</div>
  }, {
    key: '2',
    title: <div>贡献榜</div>
  }
]

const LiveDetail: React.FC<LiveDetailProps> = ({ history, match, userInfo }) => {
  const str = window.location.search.split('=')
  // eslint-disable-next-line
  const id = str[str.length - 1]
  // let pc = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? false : true
  // if(pc) {
  //   window.location.replace('https://' + window.location.host.replace('m.','www.') + '/live?id=' + id)
  // }
  const [detail, setDetail] = useState<any>({})
  const [imClose, setImClose] = useState<number>(0)
  const [anchor, setAnchor] = useState<any>({})
  const [isAttended, setAttended] = useState<boolean>(false)
  // eslint-disable-next-line
  const [showRefresh, setRefresh] = useState<boolean>(false)
  const [showDefaultAvatar, setShowDefaultAvatar] = useState<boolean>(false)

  const player = useRef<DPlayer>()
  const watchTimer = useRef<any>()
  const host = 'https://' + window.location.host.replace('m.', 'www.')
  const changeWatchTime = useCallback(() => {
    if (!userInfo.id) return

    watchTimer.current = setInterval(() => {
      if (player.current!.video.paused) return
      const time = Number(getItem(`${userInfo.id}${getDate(Date.now() / 1000, 'yy-mm-dd')}watchTime`))
      setItem(`${userInfo.id}${getDate(Date.now() / 1000, 'yy-mm-dd')}watchTime`, (time + 1000).toString())
    }, 1000)
  }, [userInfo])
  // 增加热度
  const getLiveInfo = useCallback((id) => {
    http.post('/webapi/Live/getLiveInfo', {
      liveid: id
    })
    // eslint-disable-next-line
  }, [id])
  // 获取详情(新接口)
  const getDetail = useCallback((id, originData) => {
    http.post('/webapi/Live/info', {
      anchorid: id
    })
      .then(({ data: { data } }) => {
        setImClose(data.live.im_close);
        originData.imClose = data.live.im_close
        originData.signature = data.user.signature
        setDetail(originData)
      })
    // eslint-disable-next-line
  }, [id])

  const createPlayer = useCallback((url, pic) => {
    player.current = new DPlayer({
      container: document.getElementById('player'),
      video: {
        url: url.replace('.flv', '.m3u8'),
        // url: url,
        pic
      },
      autoplay: true,
      loop: true
    })
    // @ts-ignore
    player.current.on('play', (e) => {
      changeWatchTime()
    })
    // @ts-ignore
    player.current.on('canplay', (e) => {
      player.current?.play()
    })
    // @ts-ignore
    player.current.on('error', (e) => {
      // @ts-ignore
      console.log(e)
    })
    // eslint-disable-next-line
  }, [changeWatchTime, setRefresh])

  const refreshHandler = useCallback(() => {
    console.log(detail)
    if (player.current) {
      player.current.destroy()
      createPlayer(detail.pull_url, detail.thumb)
      setRefresh(false)
    }
    // setRefresh(false)
  }, [detail, setRefresh, createPlayer])

  const getAnchor = useCallback((detail) => {
    http.post('/webapi/Anchor/getAnchorInfo', {
      anchorid: id
    })
      .then(({ data: { data } }) => {
        setAnchor(data)
      })
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    getLivePageData()
    return () => {
      if (player.current) {
        player.current.destroy()
      }
      clearInterval(watchTimer.current)
    }
    // eslint-disable-next-line
  }, [])

  const getLivePageData = useCallback(() => {
    // const { id } = match!.params as {id: string}
    http.post('/webapi/live/getLivePageData', {
      anchorid: id
    })
      .then(({ data: { data } }) => {
        getAnchor(data)
        if (!data.live) {
          setTimeout(() => {
            Toast.fail('主播休息中')
          }, 1000)
          return
        }
        getLiveInfo(data.live.liveid)
        getDetail(id, data.live)
        createPlayer(data.live.pull_url, data.live.thumb)
      })
    // eslint-disable-next-line
  }, [match, getAnchor, getLiveInfo, getDetail, createPlayer])

  const focus = useCallback(() => {
    if (!userInfo.id) {
      alert('您还未登录', '请登录使用完整功能', [
        { text: '取消', onPress: () => { } },
        { text: '确定', onPress: () => { window.location.replace('/login?page=' + window.location.pathname + window.location.search) } }
      ])
      return
    }
    const type = anchor.isattent === 0 ? 1 : 0
    http.post('/webapi/Anchor/attentAnchor', {
      anchorid: anchor.id,
      type
    })
      .then(({ data }) => {
        if (data.msg) {
          Toast.fail(data.msg)
          return
        }
        setAnchor({
          ...anchor,
          isattent: type
        })
        setAttended(Boolean(type))
      })
    // eslint-disable-next-line
  }, [anchor, userInfo])

  const updateInfo = useCallback(() => {
    // const { id } = match!.params as {id: string}
    http.post('/webapi/live/getLivePageData', {
      anchorid: id
    })
      .then(({ data: { data } }) => {
        getAnchor(data)
        if (!data.live) {
          setTimeout(() => {
            Toast.fail('主播休息中')
          }, 1000)
          return
        }
        getLiveInfo(data.live.liveid)
        getDetail(id, data.live)
      })
    // eslint-disable-next-line
  }, [match, getAnchor])
  const avatarError = useCallback(() => {
    setShowDefaultAvatar(true)
  }, [setShowDefaultAvatar])
  return (
    <LiveDetailWrap>
      <div className="download-wrap">
        <img className='logo' src={require("./logo.png")} role="presentation" alt='' />
        <div className='content'>
          <div>鲸鱼直播</div>
        </div>
        <a target={'_blank'} href={host + '/download'} rel="noopener noreferrer" className='download-btn' >
          下载APP
        </a>
      </div>
      <div id="player" />

      <Tabs
        tabBarUnderlineStyle={{ transform: 'scaleY(1.2) scaleX(0.2)' }}
        prerenderingSiblingsNumber={0}
        initialPage={'3'}
        swipeable={false}
        animated={false}
        tabs={tabs}>
        <div className="content" key='0'>
          <div className="announcement-wrap">
            {/*<img src={require('./announcement@2x.png')}/>*/}
            {/*<p className="announce-title">直播间公告:</p>*/}
            <p className='announce-content'>直播间公告:{detail.signature}</p>
          </div>
          <Message isAttend={isAttended} anchor={anchor} roomId={'LIVEROOM_' + id} imClose={imClose} updateInfo={updateInfo} detail={detail} />
        </div>
        <div className="content" key='1'>
          <div className="anchor">
            {
              showDefaultAvatar ? <img className='avatar' src={defaultAvatar} alt="" role="presentation" /> : <img className='avatar' src={anchor.avatar || defaultAvatar} alt="" role="presentation" onError={avatarError} />
            }
            {/* <img className='avatar' src={anchor.avatar || defaultAvatar} alt="" /> */}
            <p className='name'>{anchor.nick_name}</p>
            <p className='id'>ID:{anchor.id}</p>
            <div className="center">
              <p>{detail.hot}<br /><span>人气</span></p>
              <p>{anchor.fans_count}<br /><span>粉丝</span></p>
            </div>
          </div>
        </div>
        <div className="content" key='2'>
          <DetailRank anchorId={detail.anchorid} />
        </div>
      </Tabs>
      <button
        onClick={focus}
        className={anchor.isattent ? 'focus active' : 'focus'}
      >
        <img src={require('./guanzhu@2x.png')} role="presentation" alt='' />{anchor.isattent ? '已关注' : '关注'}
      </button>
      <Link to="/" className="go-home">首页</Link>
      <div className="refresh-btn" onClick={refreshHandler}><img src={require('./refresh.png')} role="presentation" alt="" /></div>
    </LiveDetailWrap>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo
})

export default connect(mapState, null)(LiveDetail)
