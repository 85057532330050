import Constants from './constants'
import http from '../common/js/http'
import { Dispatch } from 'redux'
import { setItem, getItem } from '../common/js/storage'



export function initConfig(config: any) {
  return {
    type: Constants.InitConfig,
    payload: config
  }
}

export function SetCategories(categories: any[]) {
  return {
    type: Constants.SetCategories,
    payload: categories
  }
}

export function SetTextFilter(textFilter: any[]) {
  return {
    type: Constants.SetTextFilter,
    payload: textFilter
  }
}


export function SetUserInfo(info: any) {
  return {
    type: Constants.SetUserInfo,
    payload: info
  }
}

export function SetIm(tim: any) {
  return {
    type: Constants.SetIm,
    payload: tim
  }
}


function base64Decode(base64String: string) {
  const decodedData = window.atob(base64String);
  const decoder = new TextDecoder('utf-8');
  const decodedString = decoder.decode(new Uint8Array(
    Array.from(decodedData).map(char => char.charCodeAt(0))
  ));
  return decodedString;
}


export function getConfig() {
  console.log('走不走');

  return (dispatch: Dispatch) => {
    // const config = getItem('config')
    // const categories = getItem('categories')
    // if (config && (Date.now() - config.createTime < 1000 * 60 * 60 * 24)) {
    //   dispatch(initConfig(config))
    //   dispatch(SetCategories(categories))
    //   return
    // }
    http.post('/webapi/config/getCommonConfig')
      .then(({ data: { data } }) => {
        data.config.createTime = Date.now()
        setItem('config', data.config)
        setItem('userLevelList', data.user_level_list)
        // setItem('categories', data.live_category)
        console.log(initConfig(data.config));
        dispatch(initConfig(data.config))
      })
    http.post('/webapi/live/getCategory')
      .then(({ data: { data } }) => {
        console.log(data, 'data------cate');
        setItem('categories', data)
        dispatch(SetCategories(data))
      })

    http.post('/webapi/textfilter/info')
      .then(({ data: { data } }) => {
        console.log(data.sensitive_words, 'data.sensitive_words');
        const text = base64Decode(data.sensitive_words);//解密
        console.log(text, 'text');
        const list = text.split('|')
        console.log(list, 'list');
        setItem('textFilter', JSON.stringify(list))
        dispatch(SetTextFilter(list))
      })
  }
}

export function getUserInfo() {
  return (dispatch: Dispatch) => {
    const uid = getItem('uid')
    const token = getItem('token')
    if (token && uid) {
      http.post('/webapi/User/getUserInfo', {
        token,
        uid
      })
        .then(({ data: { data } }) => {
          dispatch(SetUserInfo(data))
          setItem('nick_name', data.nick_name)
        })
    }
  }
}

