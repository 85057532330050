import React, {useCallback, useEffect, useRef, useState} from 'react'
import ScrollWrap from '../scroll-wrap/scroll-wrap'
import HappeningWrap from './style'
import {getKey} from "../../common/util/getKey";
import Percent from './percent'
import http from '../../common/js/http'

interface RaceHappeningProps {
  desc: any
}

const RaceHappening: React.FC<RaceHappeningProps> = ({ desc }) => {
  const [percents, setPer] = useState<any[]>([])
  const [msgList, setList] = useState<any[]>([])

  useEffect(() => {
    if (desc.id) {
      http.post('/webapi/football/getMatchData', {
        matchid: desc.id
      })
        .then(({ data: { data } }) => {
          setPer(data)
        })
    }
  }, [desc.id])

  useEffect(() => {
    getMsgList([])
    return () => clearTimeout(timer.current)
    // eslint-disable-next-line
  }, [])

  const timer = useRef<any>()
  const copyList = useRef<any[]>([])
  const times = useRef<number>(0)

  const getMsgList = useCallback((data: any[]) => {
    http.post('/webapi/football/getTliveData', {
      matchid: desc.id,
      startid: data[0] && data[0].id,
      hideLoading: true
    })
      .then(({ data: { data } }) => {
        timer.current = setTimeout(() => {
          if (data.length === 0) return
          getMsgList(data)
        }, 10000)
        times.current++
        copyList.current = data.concat(copyList.current)
        setList([...copyList.current])
      })
  }, [desc.id])

  return (
    <ScrollWrap loadMore={() => Promise.resolve()}>
      <HappeningWrap>
        <div className="head">
          <div className="team">
            <p>{getKey(desc, 'hometeam.short_name_zh')}</p>
            <img src={getKey(desc, 'hometeam.logo')} alt=""/>
          </div>
          <span>VS</span>
          <div className="team">
            <img src={getKey(desc, 'awayteam.logo')} alt=""/>
            <p>{getKey(desc, 'awayteam.short_name_zh')}</p>
          </div>
        </div>
        <div className="percent">
          {
            percents.map(item => {
              return <Percent leftNum={item.home} key={item.id} rightNum={item.away} type={item.type} />
            })
          }
        </div>
        <div className="msg-list">
          <h4>事件</h4>
          {msgList.map((item, index) => <p key={index}><img src={require('./ic_notice@2x.png')} alt=""/><span className='text-overflow'>{item.data}</span></p>)}
        </div>
      </HappeningWrap>
    </ScrollWrap>
  )
}

export default RaceHappening
