import styled from 'styled-components'
import trans from '../../common/util/px'

const MessageWrap = styled.div`
  flex: 1;
  height: calc(100% - ${trans(660)});

  .scroll{
    height: calc(100% - ${trans(660)});
    width: 100%;
    box-sizing: border-box;
    background-color: #F4F7FF;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    .chat-close-wrap {
      position: absolute; 
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      font-size: ${trans(28)};
    }
  }
   .announcement-wrap {
      display: flex;
      align-items: center;
      font-size: ${trans(26)};
      line-height: ${trans(30)};
      padding: ${trans(10)};
      background-color: #DDE6FF;
      
      img {
        width: ${trans(23)};
      }

      .announce-title {
        word-break: keep-all;
        color: #0086FF;
        margin-left: ${trans(5)};
      }

      .announce-content {
        color: #333333;
        line-height: 1.2;
      }
  }
  .msg-wrap {
    padding: ${trans(20)};
  }
  .tips {
    line-height: ${trans(36)};
    font-size: ${trans(28)};
    color: #0086ff;
  }
  .msg-item {
    margin: ${trans(20)} 0;
    line-height: ${trans(32)};
    font-size: ${trans(28)};
    color: #333333;
    &>span {
      color: #B3B3B3;
    }
    &>img {
      margin-right: ${trans(11)};
      width: ${trans(42)};
      height: ${trans(26)};
      vertical-align: top;
      margin-top: ${trans(3)};
    }
  }
  .form {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    height: ${trans(121)};
    display: flex;
    align-items: center;
    padding: 0 ${trans(30)} ${trans(20)};
    background-color: #fff;
    box-sizing: border-box;
    &>input {
      width: ${trans(550)};
      height: ${trans(77)};
      padding-left: ${trans(31)};
      border-radius: ${trans(38)};
      outline: none;
      border: none;
      background-color: #F5F5F5;
      box-sizing: border-box;
      &:disabled {
        background-color: #eee;
      }
    }
    &>button {
      white-space: nowrap;
      width: ${trans(120)};
      height: ${trans(70)};
      margin-left: ${trans(20)};
      border-radius: ${trans(38)};
      background-color: #0086ff;
      color: #fff;
      font-size: ${trans(28)};
      border: none;
      outline: none;
      box-sizing: border-box;
      &:disabled {
        background-color: #eee;
      }
    }
  }
`

export default MessageWrap
