import React, {useCallback, useRef, useState} from "react";
import BindPhoneWrap from "./style";
import {SetUserInfo} from "../../store/action";
import { connect } from "react-redux";
import BackHeader from "../../components/back-header/back-header";
import { RouteChildrenProps } from "react-router-dom";
import trans from "../../common/util/px";
import http from '../../common/js/http'
import {Toast} from "antd-mobile";

interface BindPhoneProps extends RouteChildrenProps {
  userInfo: any
  changeUserInfo(info: any): void
}

const BindPhone: React.FC<BindPhoneProps> = (props) => {
  const [phone, setPhone] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [pwd, setPwd] = useState<string>('')
  const [sec, setSec] = useState<number>(60)

  const timer = useRef<any>()

  const changePhone = useCallback((e: any) => {
    setPhone(e.target.value)
  }, [])

  const changeCode = useCallback((e: any) => {
    setCode(e.target.value)
  }, [])

  const changePwd = useCallback((e: any) => {
    setPwd(e.target.value)
  }, [])

  const sendCode = useCallback(() => {
    if (!/^1\d{10}$/.test(phone)) {
      Toast.fail('请输入正确的手机号', 1, undefined, false)
      return
    }
    http.post('/webapi/user/sendVerifyCode', {
      mobile: phone
    })
      .then(() => {
        Toast.success('发送成功')
        let sec = 60
        timer.current = setInterval(() => {
          setSec(--sec)
          if (sec <= 0) {
            setSec(60)
            clearInterval(timer.current)
            timer.current = undefined
            return
          }
        }, 1000)
      })
  }, [phone])

  const submit = useCallback(() => {
    if (!phone) {
      Toast.fail('请输入手机号', 1, undefined, false)
      return
    }
    if (!pwd) {
      Toast.fail('请输入密码', 1, undefined, false)
      return
    }
    if (!code) {
      Toast.fail('请输入验证码', 1, undefined, false)
      return
    }
    http.post('/webapi/User/bindPhone', {
      mobile: phone,
      smscode: code,
      pwd
    })
      .then(() => {
        Toast.success('绑定成功', 1)
        props.changeUserInfo({
          ...props.userInfo,
          account: phone
        })
        props.history.goBack()
      })
  }, [phone, code, pwd, props])

  return (
    <BindPhoneWrap>
      <BackHeader title='绑定手机号' {...props} />
      <div style={{ height: trans(40) }} />
      <div className="input">
        <input onChange={changePhone} value={phone} type="text" placeholder='请输入手机号'/>
      </div>
      <div className="input">
        <input onChange={changePwd} value={pwd} type="password" placeholder='请设置登录密码'/>
      </div>
      <div className="input">
        <input onChange={changeCode} value={code} type="text" placeholder='请输入手机验证码'/>
        <button onClick={sendCode}>{sec < 60 ? `${sec}s` : '获取验证码'}</button>
      </div>
      <button onClick={submit}>立即绑定</button>
    </BindPhoneWrap>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo
})

const mapDispatch = (dispatch: any) => {
  return {
    changeUserInfo(info: any) {
      dispatch(SetUserInfo(info))
    }
  }
}

export default connect(mapState, mapDispatch)(BindPhone)