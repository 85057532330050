import styled from 'styled-components'
import trans from '../../common/util/px'

const SearchWrap = styled.div`
  &>form {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: ${trans(20)} ${trans(30)} 0;
    justify-content: space-between;
    .input {
      flex: 1;
      padding: 0 ${trans(25)};
      display: flex;
      align-items: center;
      height: ${trans(64)};
      border-radius: ${trans(32)};
      background-color: #F2F2F2;
      &>img {
        width: ${trans(28)};
        height: ${trans(28)};
      }
      &>input {
        //flex: 1;
        width: 100%;
        height: 100%;
        margin: 0 ${trans(15)};
        font-size: ${trans(28)};
        color: #333333;
        outline: none;
        border: none;
        background: transparent;
      }
    }
    &>button {
      outline: none;
      border: none;
      margin-left: ${trans(10)};
      background: transparent;
      font-size: ${trans(28)};
      color: #000000;
    }
  }
  .no-res {
    padding-top: ${trans(208)};
    text-align: center;
    &>img {
      width: ${trans(422)};
      height: ${trans(370)};
    }
    &>p {
      padding-top: ${trans(41)};
      font-size: ${trans(30)};
      color: #A0A0A0;
    }
  }
  .list {
    .anchor {
      padding: 0 ${trans(30)};
      display: flex;
      align-items: center;
      margin-top: ${trans(42)};
      &>img {
        width: ${trans(85)};
        height: ${trans(85)};
        margin-right: ${trans(16)};
        border-radius: 50%;
      }
      .center {
        flex: 1;
        &>h4 {
          margin-bottom: ${trans(14)};
          font-size: ${trans(30)};
          color: #000;
        }
        &>p {
          font-size: ${trans(24)};
          color: #7F7F7F;
        }
      }
      &>button {
        padding: 0 ${trans(15)};
        height: ${trans(40)};
        border-radius: ${trans(20)};
        font-size: ${trans(24)};
        color: #FFFFFF;
        background: linear-gradient(81deg,rgba(255,129,33,1),rgba(255,197,42,1));
        border: none;
        outline: none;
        &.active {
          opacity: 0.6;
        }
      }
    }
  }
`

export default SearchWrap
