import React, { useState, useEffect, memo, useCallback } from "react";
// eslint-disable-next-line
import { Carousel } from "antd-mobile";
import RecWrap from "./style";
import http from "../../common/js/http";
// eslint-disable-next-line
import trans from "../../common/util/px";
// eslint-disable-next-line
import LiveItem from "../../components/live-item/live-item";
import BottomLoading from "../../components/bottom-loading/bottom-loading";
import { Link } from "react-router-dom";
import RaceItem from "./race-item";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import { defaultAvatar } from "../../common/js/config";
SwiperCore.use([Autoplay]);
const Recommend = () => {
  const [banners, setBanner] = useState<any[]>([]);
  const [races, setRaces] = useState<any[]>([]);
  const [anchors, setAnchors] = useState<any[]>([]);
  const [hots, setHots] = useState<any[]>([]);
  // const [allLives, setAll] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFinished, setFinished] = useState<boolean>(true);

  // const history = useHistory()

  useEffect(() => {
    // http.post("/webapi/Ads/getHomeScrollAd").then(({ data: { data } }) => {
    //   setBanner(data);
    // });
     http.post("/webapi/banner/list").then(({ data: { data } }) => {
       setBanner(data);
     });
     http
       .post("/webapi/home/getHomeData", {
         recommend_live_size: 8,
         page: 1,
         size: 20,
       })
       .then(({ data: { data } }) => {
         setAnchors(data.recoomend_anchors);
         setHots(data.recommend_lives);
         // setAll(data.categorys);
         setFinished(data.recommend_lives.length < 20);
         const races: any = [];
         for (
           let i = 0;
           i < Math.ceil(data.recommend_football_match.length / 2);
           i++
         ) {
           races.push(data.recommend_football_match.slice(i * 2, i * 2 + 2));
         }
         console.log(races, 'races');
 
         setRaces(races);
         setLoading(false);
       });

   /*  Promise.all([http.post("/webapi/banner/list"), http
      .post("/webapi/home/getHomeData", {
        recommend_live_size: 8,
        page: 1,
        size: 20,
      })]).then((value) => {
        console.log('====================================');
        console.log(value);
        console.log('====================================');
        setBanner(value[0].data.data);

        setAnchors(value[1].data.data.recoomend_anchors);
        setHots(value[1].data.data.recommend_lives);
        // setAll(data.categorys);
        setFinished(value[1].data.data.recommend_lives.length < 20);
        const races: any = [];
        for (
          let i = 0;
          i < Math.ceil(value[1].data.data.recommend_football_match.length / 2);
          i++
        ) {
          races.push(value[1].data.data.recommend_football_match.slice(i * 2, i * 2 + 2));
        }
        console.log(races, 'races');

        setRaces(races);
        setLoading(false);


      }).catch(() => { }) */


  }, []);

  const loadMore = useCallback(() => {
    if (loading) return;
    setLoading(true);
    http
      .post("/webapi/home/getHomeData", {
        page: page + 1,
        size: 20,
        hideLoading: page + 1 > 1,
      })
      .then(({ data: { data } }) => {
        // setAll(allLives.concat(data.categorys));
        setPage(page + 1);
        setLoading(false);
        setFinished(data.lives.length < 20);
      });
  }, [loading, page,/*  allLives */]);

  return (
    <RecWrap>
      <div
        className="banner-wrap"
        onTouchStart={(e) => {
          e.stopPropagation();
        }}
      >
        {banners.length ? (
          <Swiper
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop={true}
          >
            {banners.map((item: any) => {
              return (
                <SwiperSlide key={item.id}>
                  <a
                    href={item.jump_url}
                    className="banner-item"
                    style={{ backgroundImage: `url(${item.image_url})` }}
                  >
                    {""}
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : null}
      </div>
      {races.length ? (
        <Swiper className="race-carousel">
          {races.map((item: any, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="race-banner">
                  {item.map((race: any) => {
                    return <RaceItem race={race} key={race.id} />;
                  })}
                  <img className="dot" src={require("./more1@2x.png")} alt="" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : null}
      {anchors.length ? (
        <div className="anchors">
          <h4 className="sub-title">
            <i />
            主播推荐
          </h4>
          <div className="list">
            {anchors.map((item) => {
              return (
                <Link to={"/live?id=" + item.id} key={item.id}>
                  <div className="icon">
                    <img
                      src={item.avatar || defaultAvatar}
                      className="avatar"
                      alt=""
                    />
                    {
                      // eslint-disable-next-line
                      item.is_onlive == 1 ? (
                        <div className="voice active">
                          <img src={require("./play.png")} alt="" />
                          <span>直播中</span>
                        </div>
                      ) : (
                        <div className="voice">
                          <img src={require("./clock.png")} alt="" />
                          <span>即将开播</span>
                        </div>
                      )
                    }
                  </div>
                  <p className="text-overflow">
                    {item.nick_name} {1}
                  </p>
                </Link>
              );
            })}
          </div>
        </div>
      ) : null}
      {hots.length ? <div className="hot">
        <h4 className="sub-title">
          <i />
          热门推荐
        </h4>
        <div className="hot-list">
          {hots.map((item: any, index) => {
            return <LiveItem key={index} live={item} />;
          })}
        </div>
      </div> : null}
      {/*<div className="hot no-border">*/}
      {/*  <h4 className='sub-title'><i/>全部直播</h4>*/}
      {/*  <div className="hot-list">*/}
      {/*    {*/}
      {/*      allLives.map((item: any, index) => {*/}
      {/*        return <LiveItem key={index} live={item} />*/}
      {/*      })*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*</div>*/}
      {!isFinished && <BottomLoading loadMore={loadMore} />}
    </RecWrap>
  );
};

export default memo(Recommend);
