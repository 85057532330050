import styled from 'styled-components'
import trans from '../../common/util/px'

const HomeWrap = styled.div`
  .icon-search {
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    height: ${trans(100)};
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    box-sizing: border-box;
    &>img {
      height: 100%;
    }
  }
  width: 100%;
  padding-bottom: ${trans(96)};
  .am-tabs-content-wrap {
    padding-top: ${trans(188)};
  }
  .am-tabs-tab-bar-wrap {
    position: fixed;
    z-index: 100;
    top: ${trans(100)};
    width: 100%;
  }
  .am-tabs-default-bar {
    //width: 88vw;
    margin-bottom: ${trans(5)};
    overflow: hidden;
  }
  .am-tabs-default-bar-underline {
    border-color: #0086FF;
    background: #0086FF;
  }
`

export default HomeWrap
