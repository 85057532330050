import axios from 'axios'
import { Toast, Modal } from 'antd-mobile'
import store from '../../store'
import { SetUserInfo } from "../../store/action";

const alert = Modal.alert

// export const host = process.env.NODE_ENV === 'development' ? 'http://119.91.88.191' : 'https://test.api.jyuzb.com'
// export const host = process.env.NODE_ENV === 'development' ? 'http://api.dezhijiaowu.net' : 'http://api.dezhijiaowu.net'
// export const host = process.env.NODE_ENV === 'development' ? 'https://api.jyubfga.com' : 'https://api.jyubfga.com'
export const host = process.env.NODE_ENV === 'development' ? 'https://api.jyudds.com' : 'https://api.jyudds.com'

const instance = axios.create({
  /* headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }, */
  baseURL: host,
  timeout: 300000
})

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  const uid = localStorage.getItem('uid')

  if (typeof config.data !== 'string') {
    if (!config.data) config.data = {}
    if (token && config.data) {
      config.data.token = token
    }
    if (uid && config.data) {
      config.data.uid = uid
    }
  }
  if (!config.data.hideLoading) {
    Toast.loading('加载中...', 20)
  }
  delete config.data.hideLoading
  return config
},
  err => {
    return Promise.reject(err)
  })

instance.interceptors.response.use((res) => {
  Toast.hide()
  if (res.data.msg === '登录超时，请重新登录') {
    store.dispatch(SetUserInfo({}))
    localStorage.clear()
    if (res.config.url !== '/webapi/User/getUserInfo') {
      alert('您还未登录', '请登录使用完整功能', [
        { text: '取消', onPress: () => { } },
        { text: '确定', onPress: () => { window.location.replace('/login?page=' + window.location.pathname + window.location.search) } }
      ])
      return Promise.reject('登陆超时')
    } else {
      return Promise.reject('登陆超时')
    }
    // Toast.fail(res.data.msg, 1.5, undefined, true)
    // return Promise.reject('登陆超时')
  }
  if (res.data.status !== 0) {
    Toast.info(res.data.msg)
    return Promise.reject('操作有误')
  }
  return res
}, (err) => {
  Toast.hide()
  Toast.fail('请求超时，请稍后再试', 1.5)
  return Promise.reject(err)
})

export default instance
