import styled from 'styled-components'
import trans from '../../common/util/px'

const DownloadWrap = styled.div`
  .download-box{
    position: relative;
  }
  .banner-wrap {
    width: 100%;
    height: ${trans(320)};
    align-items: center;
  }
  .model{
    width: 100%;
    height: 100%;
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background:rgba(0,0,0,0.8);
    img{
      width: 100%;
      height: auto;
    }
  }
  .my-carousel {
    height: 100% !important;
  }
  .banner-item {
    display: block;
    width: 100%;
    height: ${trans(320)};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .appLogo{
      padding:20px;
      display: flex;
      .logo{
        width: 120px;
        height: 120px;
        border-radius: 25px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
        margin-right: 20px;
        img{
          width:100%;
          height:100%;
        }
      }
      .col{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top{
          .title{
            color:#222;
            font-size:22px;
            font-weight:600;
            margin-bottom:10px;
          }
          .info{
            color: #999;
            font-size: 14px;
          }
        }
        .btn{
          width: 72px;
          height: 28px;
          color:#fff;
          background:#ec6650;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          border-radius: 14px;
        }
      }

  }
  .score_area{
    box-sizing: border-box;
    margin: 0 20px 20px;
      .score_box {
        border: 1px solid #bbb;
        border-left: 0;
        border-right: 0;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
  
  }

  .course_area{
    text-align: center;
    .main-title{
      font-size: 22px;
      color: #222;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .main-tip{
      font-size: 14px;
      color: #666;
      margin-bottom: 6px;
    }
    .course_box{
      .ul{
        display: flex;
        justify-content: center;
        margin: 25px 0;
        span{
          display:block;
          width: 52px;
          height: 30px;
          margin-right: 5px;
          font-size: 16px;
          text-align:center;
        }
        .active{
          font-size: 18px;
          color: #fb725b;
          position: relative;
          &::after{
            content: "";
            display: block;
            height: 2px;
            width: 8px;
            background: #fb725b;
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%);
          }
        }
      }
     
    }
     .course_content{
        .img{
          width: 100%;
          height: auto;
        }
      }
  }
}

`

export default DownloadWrap
